import { createSpinner, dqsa, dqs } from './utils'

import { toaster } from './toasts'

import {getCookie} from "./utils";

const sendTestButtons = $(".send-test")
const resendTestButtons = $(".resend-test")

if (sendTestButtons.length || resendTestButtons.length) {

  const handleClick = (event) => {
    const client = new XMLHttpRequest()
    // Assumption: csrf cookie is already set (ensure_csrf_cookie decorator)
    const csrf_token = getCookie("smgcsrftoken")
    const action_url = $(event.target).data("send-url")
    const send_type = $(event.target).data("send-type")
    const initial_send = send_type.toLowerCase() === "initial"
    let employee_name = ""
    if (!initial_send) {
      employee_name= $(event.target).data("employee-name")
    }
    const onError = () => {
      let message = "Error while sending tests."
      if(!initial_send)
      {
        message = `Error while sending tests to ${employee_name}.`
      }

      toaster.makeToast({
        howToasty: toaster.DANGER,
        message: message
      })
    }
    const onSuccess = () => {
      let message = "Successfully sent tests."
      if(!initial_send)
      {
        message = `Successfully sent tests to ${employee_name}.`
      }
      toaster.makeToast({
        howToasty: toaster.SUCCESS,
        message: message
      })
    }
    const onLoad = () => (client.status === 200 ? onSuccess() : onError())


    client.onerror = onError
    client.onload = onLoad
    client.open("GET", `${window.location.origin}${action_url}`)
    client.setRequestHeader("X-CSRFToken", csrf_token)
    client.send()
  }

  resendTestButtons.on("click", handleClick)
  sendTestButtons.on("click", handleClick)

}