// Form for favoriting a report
import { paramsToObject, postForm } from './utils'
import { toaster } from './toasts'

const $saveReportForm = $('#save-report-form')

if ($saveReportForm.length) {
  const onError = event => {
    toaster.makeToast({
      howToasty: toaster.DANGER,
      message: 'Error communicating with the server. Please try again later.'
    })
  }

  const onLoad = e => {
    const client = e.target
    if (client.status === 200 && client.responseURL != '') {
      window.location.assign(client.responseURL)
    } else {
      toaster.makeToast({
        howToasty: toaster.DANGER,
        message: 'An unknown error has occured. Please try again later.'
      })
    }
  }

  $saveReportForm.on('submit', e => {
    e.preventDefault()
    const url = new URL(window.location)
    let params = new URLSearchParams(url.search)
    params = paramsToObject(params.entries())
    delete params.page
    if (Object.keys(params).length === 0) {
      toaster.makeToast({
        howToasty: toaster.WARNING,
        message: 'Apply at least one filter to save a report.'
      })
    } else {
      const paramsAsJSON = JSON.stringify(params)
      const formData = new FormData(e.target)
      formData.append('url_search_params', paramsAsJSON)
      postForm(e.target.action, formData, onError, onLoad)
    }
  })
}
