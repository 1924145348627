import { dqs, hideElement, showElement } from './utils'

// Show DateFromToRangeFilter when Custom is chosen in DateRangeFilter
const dateRangeFilterOption = dqs('option[value=use_DateFromToRangeFilter]')

if (dateRangeFilterOption != null) {
  const dateRangeFilter = dateRangeFilterOption.parentNode
  const searchParams = new URLSearchParams(window.location.search)
  const inputStartDate = dqs('#id_ds')
  const inputEndDate = dqs('#id_de')
  const divStartDate = dqs('#div_id_ds')
  const divEndDate = dqs('#div_id_de')
  const startDateParam = searchParams.get('ds')
  const endDateParam = searchParams.get('de')
  if (
    [null, ''].includes(startDateParam) &&
    [null, ''].includes(endDateParam) &&
    dateRangeFilter.value != 'use_DateFromToRangeFilter'
  ) {
    hideElement(divStartDate)
    hideElement(divEndDate)
  }
  dateRangeFilter.addEventListener('change', e => {
    const selectedValue = e.target.value
    if (selectedValue === 'use_DateFromToRangeFilter') {
      showElement(divStartDate)
      showElement(divEndDate)
    } else {
      hideElement(divStartDate)
      hideElement(divEndDate)
      inputStartDate.value = ''
      inputEndDate.value = ''
    }
  })
}
