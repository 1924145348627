let details_form = $("#effective-statement-form")

if (details_form.length > 0 ) {
    let CODE_MAPPINGS = {
        "0": {"value": "0", "label": "A/Critical"},
        "1": {"value": "1", "label": "C/B"},
        "2": {"value": "2", "label": "D"}
    }

    function updateRecoveryType(val) {
        let recovery_type_hidden = $("#id_recovery_type")
        let recovery_type_disabled = $("#id_recovery_type_disabled")
        let mapping = CODE_MAPPINGS[val]
        recovery_type_hidden.val(mapping["value"])
        recovery_type_disabled.val(mapping["label"])
    }

    let disposition_code_select = $("#id_disposition_code")
    // Set initial value
    let initial_recovery_type = disposition_code_select.val()
    if(initial_recovery_type !== "") {
        updateRecoveryType(disposition_code_select.val())
    }


    disposition_code_select.on("change", (event) => {
        let val = event.target.value
        updateRecoveryType(val)
    })
}
