import { toggleElementVisibility } from './utils'

const notificationRecipientForm = $("#notification-recipient-form")

if(notificationRecipientForm.length) {
    const current_prefix = notificationRecipientForm.attr("data-prefix")

    function updateElementIndex(el, prefix, ndx) {
        var id_regex = new RegExp('(' + prefix + '-\\d+)');
        var replacement = prefix + '-' + ndx;
        if ($(el).attr("for")) $(el).attr("for", $(el).attr("for").replace(id_regex, replacement));
        if (el.id) el.id = el.id.replace(id_regex, replacement);
        if (el.name) el.name = el.name.replace(id_regex, replacement);
    }


    function cloneMore(selector, prefix) {
        var newElement = $(selector).clone(true);
        var total = $('#id_' + prefix + '-TOTAL_FORMS').val();
        newElement.find("td").each(function() {
            updateElementIndex(this, prefix, total)
        })
        newElement.find(':input:not([type=button]):not([type=submit]):not([type=reset]):not([type="hidden"])').each(function () {
            // Remove values + checked status and update ids
            if($(this).is(':checkbox')) {
                $(this).prop('checked', false);
            }
            else {
                // Reset the value attr as well as the input value
                $(this).val('')
                $(this).attr('value', '')
            }
            updateElementIndex(this, prefix, total)
        });
        newElement.find(':input[type="hidden"]:not([name="csrfmiddlewaretoken"])').each(function () {
            var id_regex = new RegExp('(-\\d+)')
            var name = $(this).attr('name').replace(id_regex, '-' + total)
            var id = 'id_' + name;
            $(this).attr({'name': name, 'id': id})
            if(name === `${prefix}-${total}-id`){
                $(this).val("")
            }
        })

        newElement.find('.remove-recipient-row').each(function() {
            var id_regex = new RegExp('(-\\d+)')
            var name = $(this).attr('name').replace(id_regex, '-' + total)
            $(this).attr({'name': name, "data-recipient-form-number": total})
        })

        total++;
        $('#id_' + prefix + '-TOTAL_FORMS').val(total);
        $(selector).after(newElement);
        return false;
    }

    $(".delete-recipient").each((idx, element) => {
        var delete_button = document.createElement("button");
        delete_button.className = "btn btn-outline-danger remove-recipient-row";
        delete_button.innerHTML = "Delete";
        delete_button.name = `remove-recipient-row-${idx}`;
        delete_button.dataset.recipientFormNumber = idx
        $(element).after(delete_button)
    })

    function deleteForm(prefix, btn) {
        var total = parseInt($('#id_' + prefix + '-TOTAL_FORMS').val());
        var second_prefix = "tq"
        var update_options = false
        if (total > 1) {
            if(prefix === current_prefix) {
                var parent = btn.closest("tr")
                var id_input = parent.find("input[id$=id]")
                var id_value = id_input.val()
                // This row has not been saved, we can delete it.
                if (id_value === "") {
                    parent.remove()
                } else {
                    parent.css("display", "none")
                    var delete_recipient = parent.find(".delete-recipient")
                    delete_recipient.prop("checked", true)
                }
            }
            // Value only changes if we have rows we added then deleted without saving.
            var rows = $("#notification-recipient-form").find("tr").not(".empty-form").not(":has('th')")
            $('#id_' + prefix + '-TOTAL_FORMS').val(rows.length);
            for (var i = 0, formCount = rows.length; i < formCount; i++) {
                $(rows.get(i)).find(':input').each(function () {
                    // update the inputs
                    updateElementIndex(this, prefix, i);
                });
            }
        }
        return false;
    }

    $(document).on('click', '.add-row', function (e) {
        e.preventDefault();
        cloneMore('tr:not([style*="display: none"]):last', current_prefix);
        return false;
    });


    $(document).on('click', '.remove-recipient-row', function (e) {
        e.preventDefault();
        deleteForm(current_prefix, $(this));
        return false;
    });
}