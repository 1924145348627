import {showElement, hideElement, getCookie} from "../../utils";

const afpr_form = $("#affectedProductRecoveryForm")

if (afpr_form.length) {
    const partial_case_inputs = $("input[name$=partial_cases]")
    const open_case_inputs = $("input[name$=full_open_cases]")
    const unopened_case_inputs = $("input[name$=full_unopened_cases]")
    const total_partial_cases_returned = $("#id_total_partial_cases_returned")
    const total_full_cases_returned = $("#id_total_full_cases_returned")
    const total_unreturned = $("#id_total_unreturned")
    const total_returned = $("#id_num_returned")
    const total_in_inventory = $("#id_num_cases_in_inventory")

    function aggregate_inputs(inputs) {
        let result = inputs.reduce((accumulator, currentVal) => {
            let val = parseInt(currentVal.value)
            if(isNaN(val)) {
                val = 0
            }
            return accumulator + val
        }, 0)
        return result
    }

    function partial_cases_change_handler(event) {
        let new_total = aggregate_inputs(partial_case_inputs.toArray())
        total_partial_cases_returned.val(new_total)
    }
    function cases_change_handler(event) {
        let partial_cases_total = aggregate_inputs(partial_case_inputs.toArray())
        // Combine the open cases with unopened cases for total full count
        let full_total = aggregate_inputs(open_case_inputs.toArray().concat(unopened_case_inputs.toArray()))

        total_partial_cases_returned.val(partial_cases_total)
        total_full_cases_returned.val(full_total)
        // Update our total returned and total unreturned fields
        let total_returned_val = partial_cases_total + full_total
        let total_in_inventory_num = isNaN(parseInt(total_in_inventory.val())) ? 0 : parseInt(total_in_inventory.val())

        let total_unreturned_val = total_in_inventory_num - total_returned_val
        total_unreturned_val = total_unreturned_val < 0 ? 0: total_unreturned_val
        total_returned.val(total_returned_val)
        total_unreturned.val(total_unreturned_val)
    }

    partial_case_inputs.on("change", cases_change_handler)
    open_case_inputs.on("change", cases_change_handler)
    unopened_case_inputs.on("change", cases_change_handler)
}
