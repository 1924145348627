import { createSpinner, dqsa } from './utils'

const chartDivs = dqsa('.js-hook-chart-div')

// Chart rendering
if (chartDivs.length) {
  const getChartData = (chartName, json) => {
    const labels = []
    const dataPoints = []
    if (chartName != 'trainings_per_month') {
      json.forEach(obj => {
        let name = obj.name
        if (name == undefined) {
          name = `${obj.name_first} ${obj.name_last}`
        }
        labels.push(name)
        dataPoints.push({ y: obj.compliance, href: obj.href })
      })
    }
    if (chartName === 'trainings_per_month') {
      const { data } = json
      data.forEach(element => {
        labels.push(element.label)
        dataPoints.push(element.trainings_conducted)
      })
    }
    return { labels, dataPoints }
  }

  const configureChartOptions = (chartName, labels, data) => {
    if (chartName != 'trainings_per_month') {
      let scrollable = null;
      if(labels.length >= 15 && labels.length < 30) {
        scrollable = {
          minHeight: 1000
        }
      }
      else if (labels.length >=30) {
        scrollable = {
          minHeight:1500
        }
      }
      return {
        chart: {
          type: 'bar',
          scrollablePlotArea: scrollable,
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: labels
        },
        yAxis: {
          min: 0,
          max: 100,
          title: {
            text: ''
          },
          labels: {
            format: '{value}%'
          }
        },
        tooltip: {
          valueSuffix: '%'
        },
        legend: [
          {
            enabled: false
          }
        ],
        plotOptions: {
          series: {
            colorByPoint: true,
            cursor: 'pointer',
            point: {
              events: {
                click: e => {
                  if (e.point.href != undefined) {
                    location.href = e.point.href
                  }
                }
              }
            }
          },
          bar: {
            dataLabels: {
              enabled: true,
              format: '{y}%'
            }
          }
        },
        dataSorting: {
          enabled: true
        },
        series: [
          {
            data: data,
            name: 'Compliance'
          }
        ]
      }
    }
    if (chartName === 'trainings_per_month') {
      return {
        chart: {
          type: 'bar'
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: labels
        },
        yAxis: {
          title: {
            text: ''
          },
          labels: {
            format: '{value}'
          }
        },
        legend: [
          {
            enabled: false
          }
        ],
        plotOptions: {
          series: {
            colorByPoint: true
          },
          bar: {
            dataLabels: {
              enabled: true
            }
          }
        },
        dataSorting: {
          enabled: true
        },
        series: [
          {
            data: data,
            name: 'Trainings Conducted'
          }
        ]
      }
    }
  }
  chartDivs.forEach(chartDiv => {
    const spinner = createSpinner({})
    const divForCentering = document.createElement('div')
    divForCentering.setAttribute('class', 'spinner-container')
    divForCentering.appendChild(spinner)
    chartDiv.appendChild(divForCentering)
    const baseURL = chartDiv.dataset.smgDataSourceUrl
    const queryString = window.location.search
    fetch(baseURL + queryString)
      .then(response => {
        return response.json()
      })
      .then(json => {
        const chartName = json.chart_name
        const { labels, dataPoints } = getChartData(chartName, json)
        const chartOptions = configureChartOptions(chartName, labels, dataPoints)
        if (dataPoints.length) {
          divForCentering.remove()
          Highcharts.chart(chartDiv, chartOptions)
        } else {
          spinner.remove()
          const icon = document.createElement('i')
          icon.classList.add('fas', 'fa-empty-set')
          divForCentering.textContent = 'No records found.'
          divForCentering.prepend(document.createElement('br'))
          divForCentering.prepend(icon)
        }
      })
  })
}
