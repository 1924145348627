// Quick record
import { activateSelectAllCheckbox, dqsa, dqs } from './utils'

import { toaster } from './toasts'

const $buttonQuickRecord = $('#button-quick-record')

if ($buttonQuickRecord.length) {
  const $checkboxes = $('input[name="assignment"]')
  const $selectAllCheckbox = $('#input-checkbox-cross-training-select-all')
  const submit = e => {
    e.preventDefault()
    // If no checkboxes are checked
    if (Array.from($checkboxes).every(checkbox => !checkbox.checked)) {
      toaster.makeToast({
        howToasty: toaster.WARNING,
        message: 'Please select at least one assignment to record a session.'
      })
      // Else build the query string and go
    } else {
      const eidSet = new Set()
      const ttidSet = new Set()
      const pidSet = new Set()
      const checkedCheckboxes = dqsa('input[name="assignment"]:checked')
      checkedCheckboxes.forEach(c => {
        eidSet.add(c.dataset.smgEid)
        c.hasAttribute('data-smg-ttid')
          ? ttidSet.add(c.dataset.smgTtid)
          : pidSet.add(c.dataset.smgPid)
      })

      const eidArray = []
      eidSet.forEach(eid => eidArray.push(`e=${eid}`))

      const ttidArray = []
      ttidSet.forEach(ttid => eidArray.push(`tt=${ttid}`))

      const pidArray = []
      pidSet.forEach(pid => pidArray.push(`p=${pid}`))

      let queryString = `?mode=record&${eidArray.join('&')}`

      if (ttidArray.length) {
        queryString += `&${ttidArray.join('&')}`
      }

      if (pidArray.length) {
        queryString += `&${pidArray.join('&')}`
      }

      const createTrainingSessionURL = dqs('#url_create_training_session').dataset.smgUrl
      location.assign(`${location.origin}${createTrainingSessionURL}${queryString}`)
    }
  }
  $buttonQuickRecord.on('click', submit)
  activateSelectAllCheckbox($selectAllCheckbox, $checkboxes)
}
