import { createSpinner, dqsa, dqs } from './utils'

import { toaster } from './toasts'

const uploadDocumentForms = $('.upload-document-form')

if (uploadDocumentForms.length) {
  const forms = dqsa('.upload-document-form')
  const fileFields = dqsa('.custom-file-input')
  const deleteButtons = dqsa('.js-delete-document')
  const documentCount = dqs('#document-count')

  const hasFile = event => {
    if (event.target.files.length >= 1) {
      const footer = dqs(`#modal-footer-${event.target.dataset.smgId}`)
      const button = footer.querySelector('button')
      const files = event.target.files
      const filenames = []
      for (let curr_file of files) {
        filenames.push(curr_file.name)
        if (curr_file.name.length > 255) {
          button.setAttribute('disabled', '')
          toaster.makeToast({
            howToasty: toaster.WARNING,
            message: 'That file name is too long. Please rename the file.'
          })
          const fileInput = event.target
          fileInput.value = null
          const nextSibling = fileInput.nextElementSibling
          if (nextSibling != null) {
            nextSibling.innerText = 'Choose file'
          }
        } else {
          button.removeAttribute('disabled')
          const new_label = filenames.join(', ')
          const fileInput = event.target
          const nextSibling = fileInput.nextElementSibling
          nextSibling.innerText = new_label
        }
      }
    }
  }

  const handleDelete = event => {
    event.preventDefault()
    let anchor = null
    let listItem = null
    // Clicking on the trash can icon vs outside it needs different targets
    if (event.target.tagName == 'I') {
      anchor = event.target.parentElement
      listItem = event.target.parentElement.parentElement
    } else {
      anchor = event.target
      listItem = event.target.parentElement
    }

    const onError = event => {
      toaster.makeToast({
        howToasty: toaster.DANGER,
        message: 'Error while deleting the document. Please try again later.'
      })
    }

    const onLoad = event => {
      if (client.status == 204) {
        $(listItem).remove()
        documentCount.innerText = --documentCount.innerText
        toaster.makeToast({
          howToasty: toaster.SUCCESS,
          message: 'Document deleted.'
        })
      } else {
        toaster.makeToast({
          howToasty: toaster.DANGER,
          message: 'Error while deleting the document. Please try again later.'
        })
      }
    }

    const csrftoken = document.cookie.match(`(^|;) ?smgcsrftoken=([^;]*)(;|$)`)[2]
    const client = new XMLHttpRequest()
    client.onerror = onError
    client.onload = onLoad
    client.open('DELETE', anchor.href)
    client.setRequestHeader('X-CSRFToken', csrftoken)
    client.send()
  }

  const handleUpload = event => {
    event.preventDefault()
    const form = event.target
    const buttons = form.querySelectorAll('button')
    buttons[0].setAttribute('disabled', '')
    buttons[0].innerHTML = 'Uploading... '
    buttons[0].appendChild(
      createSpinner({
        small: true
      })
    )

    const onError = () => {
      const element = document.createElement('span')
      element.classList.add('badge', 'badge-danger')
      element.innerText = 'Error!'
      const footer = form.querySelector('.modal-footer')
      footer.innerHTML = ''
      footer.appendChild(element)
    }

    const onSuccess = () => {
      $("div[id^='upload-modal']").modal('hide')

      resetForm(form)

      toaster.makeToast({
        howToasty: toaster.SUCCESS,
        message: 'File successfully uploaded.'
      })

      const response = JSON.parse(client.response)
      const response_info = response.response_info
      const listGroup = dqs('#uploaded-files')

      // Do not act on completed training session status page
      if (listGroup) {
        response_info.map((resp) => {
          const newFile = document.createElement('li')
          newFile.classList.add('list-group-item', 'collapse', 'show', 'entrance-highlight')

          const externalLink = document.createElement('a')
          externalLink.setAttribute('href', resp.href)
          externalLink.setAttribute('target', '_blank')

          const icon = document.createElement('i')
          icon.classList.add('fas', 'fa-external-link')

          externalLink.appendChild(icon)

          const timestamp = document.createElement('small')
          timestamp.classList.add('text-muted', 'float-right')
          timestamp.innerText = resp.uploaded_dt

          const description = document.createElement('small')
          description.classList.add('text-muted')
          description.innerText = resp.description

          const deleteButton = document.createElement('a')
          deleteButton.classList.add(
            'btn',
            'btn-danger',
            'btn-sm',
            'float-right',
            'js-delete-document'
          )
          deleteButton.setAttribute('role', 'button')
          deleteButton.setAttribute('href', resp.delete_href)
          deleteButton.addEventListener('click', handleDelete)

          const trashIcon = document.createElement('i')
          trashIcon.classList.add('fas', 'fa-trash-alt')

          deleteButton.innerText = 'Delete'
          deleteButton.prepend(trashIcon)

          newFile.innerHTML = `&nbsp;&nbsp;${resp.filename}`
          newFile.prepend(externalLink)
          newFile.appendChild(timestamp)
          newFile.appendChild(document.createElement('br'))
          newFile.appendChild(description)
          newFile.appendChild(deleteButton)

          listGroup.appendChild(newFile)
          newFile.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })

          documentCount.innerText = ++documentCount.innerText
        })
      }
    }

    const onLoad = () => (client.status === 200 ? onSuccess() : onError())

    const client = new XMLHttpRequest()
    const FD = new FormData(event.target)
    client.onerror = onError
    client.onload = onLoad
    client.open('POST', `${window.location.origin}${form.getAttribute('action')}`)
    // Delete button
    buttons[1].addEventListener('click', () => client.abort())
    client.send(FD)
  }

  const resetForm = form => {
    form.reset()

    const buttons = form.querySelectorAll('button')
    for (let btn of buttons) {
      if (btn.innerText == 'Uploading... ') {
        btn.innerText = 'Upload'
      }
    }
  }

  for (let form of forms) {
    form.addEventListener('submit', handleUpload)
  }

  for (let field of fileFields) {
    field.addEventListener('change', hasFile)
  }

  for (let button of deleteButtons) {
    button.addEventListener('click', handleDelete)
  }
}
