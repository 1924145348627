import { dqs, animateHideElement, animateShowElement, hideElement, showElement } from './utils'

const trainingTopicForm = $('#training-topic-form')

if (trainingTopicForm.length) {
  const doesExpire = dqs('#id_expires')
  const daysValid = dqs('#div_id_valid_for_days')

  const isRegulated = dqs('#id_is_regulated')
  const regulator = dqs('#div_id_regulator')

  const requires_test = dqs('#id_requires_verification_test')
  const required_tests = dqs('#div_id_required_test')

  const toggleCollapse = e => {
    let elementToShowHide = null
    if (e.target === doesExpire) {
      elementToShowHide = daysValid
    } else if (e.target === isRegulated) {
      elementToShowHide = regulator
    } else if (e.target === requires_test) {
      elementToShowHide = required_tests
    } else {
      return null
    }
    if(elementToShowHide !== required_tests) {
      console.log(elementToShowHide)
      e.target.checked
      ? animateShowElement(elementToShowHide)
      : animateHideElement(elementToShowHide)
    }
    else {
      e.target.checked
      ? showElement(elementToShowHide)
      : hideElement(elementToShowHide)
    }

  }

  //  Show/hide based on starting state
  doesExpire.checked ? animateShowElement(daysValid) : animateHideElement(daysValid)
  isRegulated.checked ? animateShowElement(regulator) : animateHideElement(regulator)
  requires_test.checked ? showElement(required_tests): hideElement(required_tests)

  // Setup listeners
  doesExpire.addEventListener('change', toggleCollapse)
  isRegulated.addEventListener('change', toggleCollapse)
  requires_test.addEventListener('change', toggleCollapse)
}
