import {simpleRateMultiplier, valueOrZero} from "../../utils";

let final_cost_form = $("#final-cost-summary-form")

if (final_cost_form.length) {
    let reg_wage_rate = $("#id_reg_driver_wage_rate")
    let reg_hours = $("#id_reg_hours_driven")
    let reg_total = $("#id_total_driver_hour_cost")
    let ot_wage_rate = $("#id_ot_wage_rate")
    let ot_hours = $("#id_ot_hours_driven")
    let ot_total = $("#id_total_driver_ot_hour_cost")
    let payroll_tax_rate = $("#id_driver_payroll_tax_rate")
    let payroll_tax_total = $("#id_total_driver_taxes")
    let total_driver_cost = $("#id_total_driver_cost")
    let driver_title_cost = $("#driver-title-cost")

    let elems_affecting_total = [
        reg_wage_rate,
        reg_hours,
        ot_wage_rate,
        ot_hours,
        payroll_tax_rate
    ]


    function handle_reg_hours(event) {
        let total = simpleRateMultiplier(reg_hours, reg_wage_rate)
        reg_total.val(total.toFixed(2))
        return total
    }

    function handle_ot_hours(event) {
        let total = simpleRateMultiplier(ot_hours, ot_wage_rate)
        ot_total.val(total.toFixed(2))
        return total
    }

    function handle_total_driver_cost(event) {
        let payroll_tax_num = valueOrZero(payroll_tax_rate.val())
        let payroll_tax_decimal = 0
        if (payroll_tax_num !== 0) {
            payroll_tax_decimal = payroll_tax_num/100
        }
        let reg_cost = handle_reg_hours()
        let ot_cost = handle_ot_hours()
        let total_hours_cost = reg_cost + ot_cost
        let payroll_tax_cost = total_hours_cost * payroll_tax_decimal
        let total_cost = total_hours_cost + payroll_tax_cost

        payroll_tax_total.val(payroll_tax_cost.toFixed(2))
        // Trigger Change event for totalCost.js
        total_driver_cost.val(total_cost.toFixed(2)).trigger("change")
        driver_title_cost.text(`Total Cost: $ ${total_cost.toFixed(2)}`)
        return total_cost
    }

    // Initialize the total values on load
    handle_total_driver_cost(null)

    elems_affecting_total.forEach((elem) => {
        // We don't need to attach the individual event handlers since they are called in handle_total_driver_cost
        elem.on("change", handle_total_driver_cost)
    })
}