import { createSpinner } from './utils'

const reportStatBadges = document.querySelectorAll('[data-smg-report-stat-url]')

if (reportStatBadges.length) {
  for (let element of reportStatBadges) {
    const spinner = createSpinner({ small: true })
    element.appendChild(spinner)

    const url = element.dataset.smgReportStatUrl

    fetch(url)
      .then(response => {
        return response.json()
      })
      .then(json => {
        spinner.remove()
        const percent = json.verifiedPercent
        const label = percent !== null ? `${percent}%` : 'N/A'
        element.innerText = label
      })
  }
}
