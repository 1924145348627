import { toggleElementVisibility } from './utils'

const trainingTestForm = $("#training-test-form")

if(trainingTestForm.length) {
    let currentDraggableType = null
    const is_even_weight = $("#id_evenly_weight_questions")
    const all_initial_points = $(".question-percentage")

    function updateElementIndex(el, prefix, ndx) {
        var id_regex = new RegExp('(' + prefix + '-\\d+)');
        var replacement = prefix + '-' + ndx;
        if ($(el).attr("for")) $(el).attr("for", $(el).attr("for").replace(id_regex, replacement));
        if (el.id) el.id = el.id.replace(id_regex, replacement);
        if (el.name) el.name = el.name.replace(id_regex, replacement);
    }


    function cloneMore(selector, prefix) {
        var newElement = $(selector).clone(true);
        newElement.addClass(".unsaved-item")
        if (newElement.hasClass("d-none")) {
            newElement.removeClass("d-none")
        }

        var total = $('#id_' + prefix + '-TOTAL_FORMS').val();
        // Add a selector for the IDX values
        var idxSelector = `#id_${prefix}-${total}-idx`
        newElement.find(':input:not([type=button]):not([type=submit]):not([type=reset]):not([type="hidden"]):not([type=checkbox])').each(function () {
            // Remove values + checked status and update ids
            $(this).val('');
            updateElementIndex(this, prefix, total)
        })
        // Checkboxes are excluded from above so that value is not overwritten
        // If we overwrite value to '', then the browser does not set the value to 'on' when submitting the form for checked items
        // Checkboxes should be unchecked after cloned.
        newElement.find('input[type=checkbox]').each(function() {
            $(this).prop('checked', false)
            updateElementIndex(this, prefix, total)
        })
        newElement.find('label').each(function () {
            var forValue = $(this).attr('for');
            if (forValue) {
                if (prefix === "testquestion_set"){
                    forValue = forValue.replace('-' + (total - 1) + '-', '-' + total + '-');
                } else {
                    forValue = forValue.replace(`multiplechoiceoption_set-${total-1}-`, `multiplechoiceoption_set-${total}-`)
                }
                $(this).attr({'for': forValue});
            }
        });
        // Replace all tooltips so that the hover works appropriately
        newElement.find("[data-toggle='tooltip']").each(function() {
            const newTooltip = this.cloneNode(true)
            $(newTooltip).tooltip();
            $(this).replaceWith(newTooltip)
        })
        if (prefix === "testquestion_set") {
            newElement.find(':input[type="hidden"]:not([name="csrfmiddlewaretoken"])').each(function () {
                var name = $(this).attr('name').replace('-' + (total - 1) + '-', '-' + total + '-');
                var id = 'id_' + name;

                $(this).attr({'name': name, 'id': id})
            })
            newElement.find('button').each(function () {
                $(this).attr({"data-testQuestionNum": total})
                let target = $(this).attr("data-target")
                if(target !== undefined && target !== "" && target !== null)
                {
                    let new_target = `tq-${total}`
                    $(this).attr("data-target", `#${new_target}`)
                    $(this).attr("aria-controls", new_target)
                }
            })
            newElement.find(idxSelector).each(function () {
                let val = parseInt($(this).val())
                $(this).val(parseInt($(this).val()) + 1)
            })
            newElement.attr('id', `tq-${total}-card`)
            newElement.find('.card-body').each(function() {
                $(this).attr('id', `tq-${total}`)
            })
            // Set the MC options INITIAL_FORMS to 0 to allow for saving
            // Initial forms should never be > total forms. Deleted forms are included in the total_forms count
            newElement.find(`#id_mcoption-testquestion_set-${total}-multiplechoiceoption_set-INITIAL_FORMS`).val(0)
            newElement.find(".mc-option").each(function () {
                $(this).addClass(".unsaved-item")
                updateElementIndex(this, `tq`, total)
                $(this).attr('data-testquestionnum', total)
            })
            currentDraggableType = "tq"
        }
        else {
            currentDraggableType="mc"
            let question_num = $(newElement.find('.remove-option-row:last')).attr('data-testquestionnum')
            newElement.attr('id', `tq-${question_num}-mc-${total}`)
            newElement.find(':input[type="hidden"]:not([name="csrfmiddlewaretoken"])').each(function () {
                updateElementIndex(this, prefix, total)
            })
        }

        total++;
        $('#id_' + prefix + '-TOTAL_FORMS').val(total);
        $(selector).after(newElement);
        var delete_button = document.createElement("button");
        delete_button.className = "mx-2 btn btn-outline-danger remove-question-row";
        delete_button.innerHTML = "Remove Question";
        delete_button.name = `remove-question-row-${prefix}-${total}`;
        if (newElement.find(".remove-question-row").length === 0) {
            newElement.find(".input-group-append").append(delete_button);
        }
        // Create a custom Drop Event. Set isReload to signal that we don't have an originalEvent for the handlers
        let custom_event = $.Event("drop", {isReload: true});
        newElement.trigger(custom_event)
        return false;
    }

    function deleteForm(prefix, btn) {
        var total = parseInt($('#id_' + prefix + '-TOTAL_FORMS').val());
        var second_prefix = "tq"
        var update_options = false
        var update_necessary = false
        if (total > 1) {
            if (prefix === "testquestion_set") {
                var parent = btn.closest(".test-question-form")
                // Hide the parent
                if(parent.hasClass(".unsaved-item")) {
                    parent.remove()
                } else {
                    parent.addClass("d-none")
                    // mark for deletion (We need to send the whole form to the POST which is why we hide it)
                    var delete_question = parent.find(".delete-question input")
                    delete_question.prop("checked", true)
                    update_options = true
                    update_necessary = true
                }
                var forms = $('.test-question-form');


            } else {
                var parent = btn.closest(".mc-option")
                var question_num = $(btn).attr('data-testquestionnum')
                if(parent.hasClass(".unsaved-item")) {
                    parent.remove()
                } else {
                    parent.addClass("d-none")
                    var delete_option = parent.find(".delete-option input")
                    delete_option.prop("checked", true)

                    second_prefix = `tq-${question_num}-mc`
                    update_necessary = true
                }
                var forms = $(`.mc-option[data-testquestionnum="${question_num}"]`)
            }

            $('#id_' + prefix + '-TOTAL_FORMS').val(forms.length);

            if (update_necessary) {
                for (var i = 0, formCount = forms.length; i < formCount; i++) {
                    $(forms.get(i)).find(':input').each(function () {
                        // update the inputs
                        updateElementIndex(this, prefix, i);
                    });
                    // Update the individual options "tq-QUESTION_NUM-mc-OPTION_NUM
                    if (update_options) {
                        $(forms.get(i)).find(".mc-option").each(function () {
                            updateElementIndex(this, second_prefix, i)
                            $(this).attr('data-testquestionnum', i)
                        })
                    }
                    // Update the parent forms
                    updateElementIndex(forms.get(i), second_prefix, i)
                }
            }

        }
        return false;
    }

    function update_uniform_points() {
        let question_count = parseInt($("#id_testquestion_set-TOTAL_FORMS").val())
        let point_amount = 100 / question_count
        $(".question-percentage").each((index, element) => {
            $(element).val(point_amount)
            $(element).prop("readonly", true)
        })
    }

    $(document).on('click', '.add-option-row', function (e) {
        e.preventDefault();
        var testQuestionNum = this.getAttribute('data-testQuestionNum')
        var prefix = `mcoption-testquestion_set-${testQuestionNum}-multiplechoiceoption_set`
        // Get the parent test-question form. Then find the optionWrapper class to use for cloning
        var option_selector = $(this).closest(".test-question-form").find(".mc-option:last")
        cloneMore(option_selector, prefix);


        return false;
    });

    $(document).on('click', '.add-question-row', function (e) {
        e.preventDefault();
        cloneMore('.test-question-form:last', 'testquestion_set');
        if(is_even_weight.prop("checked") === true) {
            update_uniform_points()
        }
        // Update all the MC Option IDX values
        $(".test-question-form").each((index, element) => {
            // For each option in this question, update the idx value
            $(element).find("[data-draggable-type='mc'] .option-order > input").each((index1, element1) => {
                element1.value = index1
            })
        })
        return false;
    });


    $(document).on('click', '.remove-question-row', function (e) {
        e.preventDefault();
        deleteForm('testquestion_set', $(this));
        if(is_even_weight.prop("checked") === true) {
            update_uniform_points()
        }
        return false;
    });

    $(document).on('click', '.remove-option-row', function (e) {
        e.preventDefault();
        var testQuestionNum = this.getAttribute('data-testQuestionNum')
        deleteForm(`mcoption-testquestion_set-${testQuestionNum}-multiplechoiceoption_set`, $(this));
        return false;
    });

    function toggle_percentage() {
        let passing_percentage_div = $("#div_id_passing_percentage")
        let passing_points_div = $("#div_id_passing_points")
        let question_points_div = $(".div_question_points")
        let question_percentage_div = $(".div_question_percentage")
        passing_percentage_div.css("display", "block")
        passing_points_div.css("display", "none")
        question_percentage_div.each((index, element) => {
            $(element).css("display", "block")
        })
        question_points_div.each((index, element) => {
            $(element).css("display", "none")
        })

    }

    function toggle_points() {
        let passing_percentage_div = $("#div_id_passing_percentage")
        let passing_points_div = $("#div_id_passing_points")
        let question_points_div = $(".div_question_points")
        let question_percentage_div = $(".div_question_percentage")
        passing_points_div.css("display", "block")
        passing_percentage_div.css("display", "none")
        question_percentage_div.each((index, element) => {
            $(element).css("display", "none")
        })
        question_points_div.each((index, element) => {
            $(element).css("display", "block")
        })
    }

    function evenly_weight_init() {
        update_uniform_points()
        toggle_percentage()
    }

    // Check default value
    is_even_weight.prop("checked") ? evenly_weight_init() : toggle_points()

    // No longer needed since they will always be readonly from the formside
    function toggle_read_only() {
        $(".question-percentage").each((index, element) => {
            if($(element).prop("readonly")) {
                $(element).prop("readonly", false)
            }
            else {
                $(element).prop("readonly", true)
                update_uniform_points()
            }
        })
    }



    function even_weight_toggle(event) {
        // toggle_read_only()
        if($(event.target).prop("checked")) {
            toggle_percentage()
        }
        else {
            toggle_points()
        }

    }

    is_even_weight.on("change", even_weight_toggle )
    $(".option-container").each( (index, element) => {
        let question_num = $(element).find(".add-option-row")[0].getAttribute('data-testQuestionNum')
        // console.log($(element).find(".remove-option-row"))
        $(element).find(".remove-option-row").each((index1, element1) => {
            $(element1).attr('data-testQuestionNum', question_num)
        })
    })

    const validateDraggableType = e => {
        return currentDraggableType === e.currentTarget.dataset.draggableType
    }

    const draggableQuestionSet = new Set()
    const draggableOptionSet = new Set()
    // Initialize sets on page load
    $("[data-draggable-type='tq']").each((idx, element) => {
        draggableQuestionSet.add(element.id)
    })
    $("[data-draggable-type='mc']").each((idx, element) => {
        draggableOptionSet.add(element.id)
    })
    const dragstartHandler = e => {
        e.dataTransfer = e.originalEvent.dataTransfer
        e.dataTransfer.setData('text/plain', e.target.id)
        e.dataTransfer.dropEffect = 'move'
        currentDraggableType = e.target.dataset.draggableType
    }

    const dragEnterHandler = e => {
        if (validateDraggableType(e)) {
            e.preventDefault()
            e.originalEvent.dataTransfer.dropEffect = 'move'
        }
    }

    const dragoverHandler = e => {
        if (validateDraggableType(e)) {
            e.preventDefault()
            e.dataTransfer = e.originalEvent.dataTransfer
            e.dataTransfer.dropEffect = 'move'
        }
    }

    const dropHandler = e => {
        // Current target is the element we are dropping onto
        if (validateDraggableType(e)) {
            e.preventDefault()
            let elementId = null
            // Is this a custom event? If so we don't have originalEvent.dataTransfer
            if(e.isReload) {
                elementId = $(e.currentTarget).attr('id')
            } else {
                e.dataTransfer = e.originalEvent.dataTransfer
                elementId = e.dataTransfer.getData('text/plain')
            }
            let element_jq = $(`#${elementId}`)
            let drag_type = element_jq.attr('data-draggable-type')
            const draggable = document.querySelector(`#${elementId}`)
            if(drag_type === "mc") {
                let question_num = element_jq.attr('data-testquestionnum')
                let dropped_question_num = $(e.currentTarget).attr('data-testquestionnum')
                if(question_num !== dropped_question_num) {
                    // If the question numbers don't match. Do nothing
                    return
                }
                $(draggable).addClass('bounce-top')
                e.currentTarget.after(draggable)
                // Get the option order relating to the specific test question
                $(
                    `#tq-${question_num}-card [data-draggable-type='${e.currentTarget.dataset.draggableType}'] .option-order > input`
                ).each((index, element) => {
                    element.value = index
                })

            }
            else {
                $(draggable).addClass('bounce-top')
                e.currentTarget.after(draggable)
                $(".test-question-form:not(.d-none)").find(
                    `.js-hook-question-number`
                ).each((index, element) => {
                    element.innerHTML = `<i class="fal fa-grip-lines mr-2" data-toggle="tooltip" title="Drag Me"></i>Question ${index + 1}`
                })

                $(".test-question-form:not(.d-none)").find(
                    `.question-order > input`
                ).each((index, element) => {
                    element.value = index
                })
            }
        }
    }

    $(trainingTestForm).on("submit", (event) => {
        // Retrieve all test question forms ordering
        // If the value of any ordering is undefined, then they have not been reordered
        // Set the ordering (Should only be during initial test creation)
        $(`[data-draggable-type=tq] .question-order > input`).each((index, element) => {
            if(element.value === "" || element.value === null || element.value === undefined) {
                element.value = index
            }
        })
        // Do the same thing for option-orders but order should be related to the test question
        // Meaning we can't iterate over all options, we must iterate over all options in a test question
        $(".test-question-form").each((index, element) => {
            $(`#${element.id} [data-draggable-type=mc] .option-order > input`).each((index2, element2) => {
                if(element2.value === "" || element2.value === null || element2.value === undefined) {
                    element2.value = index2
                }
            })
        })

    })

    $(document).on('dragstart', '.draggable-test', dragstartHandler)
    $(document).on('dragenter', '.draggable-test', dragEnterHandler)
    $(document).on('dragover', '.draggable-test', dragoverHandler)
    $(document).on('drop', '.draggable-test', dropHandler)

}