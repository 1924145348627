import { dqs } from './utils'
import { toaster } from './toasts'

const canvas = dqs('#canvas-signature-pad')
if (canvas != null) {
  const signaturePad = new SignaturePad(canvas)
  const form = dqs('#signature-form')

  const validateAndConvertSignature = event => {
    if (signaturePad.isEmpty()) {
      event.preventDefault()
      toaster.makeToast({
        howToasty: toaster.DANGER,
        message: 'Please sign your full name.'
      })
    } else {
      dqs('#id_signature').value = signaturePad.toDataURL()
    }
  }

  const clearSignaturePad = event => {
    event.preventDefault()
    signaturePad.clear()
  }

  form.addEventListener('submit', validateAndConvertSignature)
  form.addEventListener('reset', clearSignaturePad)
}
