import { dqs, dqsa } from './utils'

const form = dqs('#saved-reports-list-form')

if (form !== null) {
  const dragStartHandler = e => {
    e.originalEvent.dataTransfer.effectAllowed = 'move'
    e.originalEvent.dataTransfer.setData('text/plain', e.currentTarget.id)
  }

  const dragEnterHandler = e => {
    e.preventDefault()
    e.originalEvent.dataTransfer.dropEffect = 'move'
    e.currentTarget.classList.add('valid-drop-target')
  }

  const dragOverHandler = e => {
    e.preventDefault()
    e.originalEvent.dataTransfer.dropEffect = 'move'
  }

  const dragLeaveHandler = e => {
    e.currentTarget.classList.remove('valid-drop-target')
  }

  const dropHandler = e => {
    e.preventDefault()
    const data = e.originalEvent.dataTransfer.getData('text/plain')
    $(`#${data}`).insertBefore(e.currentTarget)
    e.currentTarget.classList.remove('valid-drop-target')
  }

  // Setup events for draggables
  $(document).on('dragstart', '.draggable', dragStartHandler)
  $(document).on('dragenter', '.draggable', dragEnterHandler)
  $(document).on('dragover', '.draggable', dragOverHandler)
  $(document).on('dragleave', '.draggable', dragLeaveHandler)
  $(document).on('drop', '.draggable', dropHandler)

  const getReportIDsFromHTML = htmlCollection => {
    const ids = []
    htmlCollection.forEach(report => ids.push(report.dataset.smgId))
    return ids
  }

  const handleSubmit = e => {
    const reports = dqsa("[id^='smg-report-']")
    const reportsIds = getReportIDsFromHTML(reports)

    $(form).append(`<input type="hidden" name="report_ids" value="${reportsIds}"/>`)

    return true
  }

  form.onsubmit = handleSubmit
}
