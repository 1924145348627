import { dqs, hideElement, showElement } from './utils'

const formCompanyLocation = dqs('#company-location-form')

if (formCompanyLocation != null) {
  const allowSessionNotifications = dqs('#id_allow_session_notifications')
  const allowSendPolicies = dqs('#id_allow_send_policies')
  const communicationPreference = dqs('#div_id_communication_preference')

  const toggleCommPref = () => {
    allowSessionNotifications.checked || allowSendPolicies.checked
      ? showElement(communicationPreference)
      : hideElement(communicationPreference)
  }

  //  Show/hide based on starting state
  toggleCommPref()

  allowSessionNotifications.addEventListener('change', toggleCommPref)
  allowSendPolicies.addEventListener('change', toggleCommPref)
}
