const es_trs = $("button.es-tr-collapsible")

if (es_trs.length) {
    function handleCollapseClick(event) {
        let target_jq = $(event.currentTarget)
        let data_target = target_jq.data("target")

        let icon_element_jq = $(data_target).find("i.expand-row")
        let has_plus_class = icon_element_jq.hasClass("fa-plus-square")
        if (has_plus_class) {
            $(data_target).removeClass("d-none")
            icon_element_jq.removeClass("fa-plus-square")
            icon_element_jq.addClass("fa-minus-square")
        } else {
            $(data_target).filter(".sub-row, .es-td-toggle").addClass("d-none")
            icon_element_jq.removeClass("fa-minus-square")
            icon_element_jq.addClass("fa-plus-square")
        }
    }

    es_trs.on("click", handleCollapseClick)
}