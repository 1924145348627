import {toaster, toaster as Toaster} from "../toasts";
import {createDatePickerItem} from "../utils";

export default function initialize_alpine() {
    // window.Alpine = Alpine
    document.addEventListener("alpine:init", () => {
        initialize_dc_select2()
        initialize_dc_context()
        initialize_dc_urls()
        initialize_dc_report()
        initialize_affected_product_set()
        initialize_affected_product()
        initialize_affected_product_select2()
        initialize_affected_code_date_set()
        initialize_affected_product_code_date()
        initialize_recovery_calendar()
    })
    // Alpine is already started from cdn.js?
    // window.Alpine.start()
}

export function initialize_dc_select2() {
    window.Alpine.data('AffectedDCSelect2', (element_id, initial_options = [], multiple = false, value = []) => ({
        multiple: multiple,
        value: value[0],
        options: initial_options,
        element_id: element_id,
        loading: true,
        init() {
            this.fetchOptions()
            this.bootSelect2()
            this.$dispatch('dc_selected', {
                dc: [{"id": value[0].id, "is_dc_hub": value[0].is_dc_hub}]
            })
            $(this.$refs.select).on('change', () => {
                let currentSelection = $(this.$refs.select).select2('data')
                // TODO Change emit data
                let emit_data = {
                    dc: currentSelection,
                }
                this.$dispatch('dc_selected', emit_data)

                this.value = this.multiple
                    ? currentSelection.map(i => i.id)
                    : currentSelection[0]
            })
            this.$watch('value', () => this.refreshSelect2())
            this.$watch('options', () => this.refreshSelect2())
        },
        fetchOptions() {
            let retrieve_url = $(`#${element_id}`).attr("data-retrieve-url")
            window.fetch(retrieve_url).then((data) => {
                return data.json()
            }).then((json) => {
                this.options = json["distribution_centers"]
                if (this.options.length > 1 ){
                    let all_option = {
                    id: -1,
                    name: "All DC's",
                    number: -1,
                    dc_date_value_id: -1,
                    time_zone: "America/New_York",
                    is_dc_hub: false,
                }
                this.options.unshift(all_option)
                }

                this.loading = false
            }).catch((error) => {
                console.log(error)
            })
        },
        bootSelect2() {
            let selections = this.multiple ? this.value : [this.value]
            let custom_data = [{id:-1, is_dc_hub: false}]
            $(this.$refs.select).select2({
                width: "style",
                matcher: this.matchCustom,
                multiple: this.multiple,
                containerCssClass: "d-flex justify-content-center align-items-center",
                data: [].concat(this.options.map(i => {
                    return ({
                        id: i.id,
                        text: i.name,
                        selected: selections.map(x => {
                            return String(x.id)
                        }).includes(String(i.id)),
                        multiple: this.multiple,
                        dc_date_value_id: i.dc_date_value_id,
                        is_dc_hub: i.is_dc_hub
                    })
                })),
            })
        },
        refreshSelect2() {
            $(this.$refs.select).select2('destroy')
            this.$refs.select.innerHTML = ''
            this.bootSelect2()
        },
        setOptions(new_options) {
            this.options = new_options
        },

        matchCustom(params, data) {
            // Matches supplier_name, supplier_wsi, wrin, or wrin_description
            // If there are no search terms, return all of the data
            if (window.$.trim(params.term) === '') {
                return data;
            }

              // Do not display the item if there is no 'text' property
            if (typeof data.text === 'undefined') {
                return null;
            }

            // `params.term` should be the term that is used for searching
            // `data.text` is the text that is displayed for the data object
            // console.log(params.term)
            // console.log(data.text)
            if (
                data.text.toLowerCase().indexOf(params.term.toLowerCase()) > -1) {
                let modifiedData = window.$.extend({}, data, true);

                // You can return modified objects from here
                // This includes matching the `children` how you want in nested data sets
                return modifiedData;
            }

            // Return `null` if the term should not be displayed
            return null;
        },
    }))
}


export function initialize_recovery_calendar() {
    window.Alpine.data("RecoveryCalendar", (init_obj = {
        newEventTitle: null,
        newEventStart: null,
        newEventEnd: null,
    }) => ({
        calendar: null,
        events: [
        ],
        newEventTitle: init_obj.newEventTitle,
        newEventStart: init_obj.newEventStart,
        newEventEnd: init_obj.newEventEnd,
        fetchedDeadlines: [],
        selected_dc: null,
        initial_load: true,
        init() {
            this.fetchCalendarItems()
            this.calendar = new FullCalendar.Calendar(this.$refs.calendar, {
                events: (info, success) => success(this.events),
                buttonText: {
                    today: "Today"
                },
                headerToolbar: {
                    start: "prev",
                    center: "title",
                    end: "today next",
                },
                initialDate: new Date(),
                initialView: 'dayGridMonth',
                selectable: false,
                unselectAuto: false,
                editable: false,
                disableDragging: true,
                rerenderDelay: 100,
                select: (info) => {
                    this.newEventStart = info.startStr
                    this.newEventEnd = info.endStr
                },
                eventClick: (info) => {
                    // This is called when the user clicks a calendar item (effective statement, afps, etc)
                },
                eventChange: (info) => {
                    const index = this.getEventIndex(info)

                    this.events[index].start = info.event.startStr
                    this.events[index].end = info.event.endStr
                },
                eventDidMount(arg,) {
                    // Append the recovery_type to the event with a line break if it exists
                    if(arg.event.extendedProps.recovery_type != '' && typeof arg.event.extendedProps.recovery_type  !== "undefined")
                    {
                        $(arg.el).find(".fc-event-title").append(`<br>${arg.event.extendedProps.recovery_type}`)
                    }
                    // Append the description to the event with a line break if it exists
                    if(arg.event.extendedProps.description != '' && typeof arg.event.extendedProps.description  !== "undefined")
                    {
                        $(arg.el).find(".fc-event-title").append(`<br>${arg.event.extendedProps.description}`)
                    }

                },
            })

            this.calendar.render()
        },
        getEventIndex(info) {
            return this.events.findIndex((event) => event.id == info.event.id)
        },
        addEvent() {
            if (! this.newEventTitle || ! this.newEventStart) {
                return alert('Please choose a title and start date for the event!')
            }

            let event = {
                id: Date.now(),
                title: this.newEventTitle,
                start: this.newEventStart,
                end: this.newEventEnd,
            }

            this.events.push(event)
            this.calendar.refetchEvents()

            this.newEventTitle = null
            this.newEventStart = null
            this.newEventEnd = null

            this.calendar.unselect()
        },
        setSelectedDC(data) {
            let dc_info = data.detail.dc[0]
            // Initial value is an int -1
            if(dc_info.id === '-1' || dc_info.id === -1) {
                this.selected_dc = null
            } else {
                this.selected_dc = dc_info.id
            }
            this.fetchCalendarItems()
        },

        fetchCalendarItems() {
            let retrieve_url = ""
            if(this.selected_dc === null  || parseInt(this.selected_dc) < 0) {
                retrieve_url = $("#recovery-calendar").attr("data-retrieve-url")
            }
            else {
                let post_url = `https://${window.location.host}`
                retrieve_url = post_url + `/stock/api/v1/dc/${this.selected_dc}/get/calendar/items/`
            }
            window.fetch(retrieve_url).then((data) => {
                return data.json()
            }).then((json) => {
                this.fetchedDeadlines = json["deadlines"]
                this.formatEvents()
                this.addEventCounts()

            }).catch((error) =>{
                console.log(error)
            })
        },
        addEventCounts() {
            let afps_type = "afps"
            let afpr_type = "afpr"
            let fcs_type = "fcs"
            let afps_list = []
            let afpr_list = []
            let fcs_list = []
            this.fetchedDeadlines.forEach((element, index) => {
                if(!element.complete) {
                    switch(element.deadline_type) {
                        case afps_type:
                            afps_list.push(element)
                            break
                        case afpr_type:
                            afpr_list.push(element)
                            break
                        case fcs_type:
                            fcs_list.push(element)
                    }
                }
            })
            afps_list.length > 0 ? $("#count_afps").text(afps_list.length) : $("#count_afps").text("")
            afpr_list.length > 0 ? $("#count_afpr").text(afpr_list.length) : $("#count_afpr").text("")
            fcs_list.length > 0 ? $("#count_fcs").text(fcs_list.length) : $("#count_fcs").text("")
        },
        formatEvents() {
            let new_events = []
            this.fetchedDeadlines.forEach((element, index) => {
                let new_element = {...element}
                // This is our TZ Aware datetime
                let element_date = new Date(element.deadline_date)
                // The calendar display does not currently care about time, grab just the date from our TZ aware event
                let date_without_time = new Date(element_date.getFullYear(), element_date.getMonth(), element_date.getDate());
                // Since our date_without_time has no TZ object attached, we can call .toISOString() and remove the UTC zoneinfo for formatting
                let formatted_date_str = date_without_time.toISOString().replace(/T.*/,'').split('/').join('-')
                new_element["start"] = formatted_date_str
                new_element["title"] = `${element.stock_recovery_number}`
                new_element["recovery_type"] = element.recovery_type_label
                new_element["description"] = element.deadline_type_string
                new_element["url"] = element.href
                new_element["classNames"] = ["custom-calendar-event", element.color_class]
                new_events.push(new_element)
            })

            this.events = new_events

            this.$nextTick(() => {
                this.calendar.refetchEvents()
                if(this.initial_load) {
                    this.initial_load = false
                }
            })
        }
    }))
}

export function initialize_affected_product() {
    window.Alpine.data('AffectedProductData', (
        id =null,
        effective_statement_id=null,
        product = {name: '', wrin: '', prod_group: '', group_description: '', user_supplied: false, id: null},
        parent_supplier= {wsi_number: '', name: '', city: '', state: '', address: '', email: '', user_supplied: false},
        supplier={wsi_number: '', name: '', city: '', state: '', address: '', email: '', user_supplied: false},
        display_lookup=true,
        manual_mode=false,
        supplier_checkbox=true,

        ) => ({
            id: id,
            wrin: product.wrin,
            wrin_description: product.name,
            product_group: product.prod_group,
            group_description: product.group_description,
            item_id: product.id,
            parent_wsi: parent_supplier.wsi_number,
            production_wsi: supplier.wsi_number,
            parent_supplier_name: parent_supplier.name,
            parent_supplier_city: parent_supplier.city,
            parent_supplier_state: parent_supplier.state,
            supplier_name: supplier.name,
            supplier_city: supplier.city,
            supplier_state: supplier.state,
            post_loading: false,
            manual_mode: manual_mode,
            display_lookup: display_lookup,
            effective_statement_id: effective_statement_id,
            supplier_checkbox: supplier_checkbox,
            selected_product_id: null,

            init() {
                this.$watch('manual_mode', (new_val) => {
                    if(new_val) {
                        this.display_lookup = false
                        this.clearProductInfo()
                    }
                    else {
                        this.clearProductInfo()
                        this.display_lookup = true
                    }
                })
            },

            ajaxPost(event) {
                this.post_loading = true
                event.preventDefault()
                let action_url = $(event.target).attr("action")
                // Our action-url should be the one to save new-items
                // If we have an item ID, this obj already exists and we need to append the item ID to update the existing one
                if(this.item_id !== null && this.item_id !== undefined && this.item_id !== "") {
                    action_url += `${this.item_id}/`
                }

                let data = new FormData(event.target)
                data.set("manual_mode", this.manual_mode)
                // Copy parent supplier info to supplier info if applicable
                if(this.manual_mode && this.supplier_checkbox) {
                    this.copyParentSupplierInfo()
                    data.set("supplier_name", this.parent_supplier_name)
                    data.set("supplier_city", this.parent_supplier_city)
                    data.set("supplier_state", this.parent_supplier_state)

                }

                let successFunction = (data) => {
                    // Don't use camelcase when dispatching events, DOM will lowercase our event-listener attribute
                    this.$dispatch('product_added')
                    // Call close modal before setting values.
                    // Setting the item_id will prevent closeModal from closing the correct modal if this was a new-item
                    this.closeModal()
                    this.post_loading = false
                    let {product, parent_supplier, supplier, ...remaining} = data
                    if(!this.id) {
                        this.clearProductInfo()
                    }

                    Toaster.makeToast({
                        howToasty: toaster.SUCCESS,
                        message: "Affected Product Information has been saved."
                    })
                }
                let errorFunction = (xhr) => {
                    console.error(xhr)
                    Toaster.makeToast({
                        howToasty: toaster.DANGER,
                        message: "Failed to add product. Please contact an administrator with error code: SMG-X1"
                    })
                    this.post_loading = false
                }
                $.ajax({
                    type: "POST",
                    url: action_url,
                    data: data,
                    processData: false,
                    contentType: false,
                    success: successFunction,
                    error: errorFunction,
                })
            },
            ajaxDeletePost(event) {
                event.preventDefault()
                let data = new FormData(event.target)
                let post_url = `https://${window.location.host}`
                if(this.id !== null && this.id !== undefined && this.effective_statement_id !== null) {
                    post_url += `/stock/effective_statements/${this.effective_statement_id}/delete/affected/product/${this.id}/`
                }
                this.post_loading = true
                let successFunction = (data) => {
                    this.closeModal()
                    this.post_loading = false
                    // Don't use camelcase when dispatching events, DOM will lowercase our event-listener attribute
                    // We can dispatch product added to refetch the items
                    this.$nextTick(() => {
                        this.$dispatch('product_added')
                    })

                    // Call close modal before setting values.
                    // Setting the item_id will prevent closeModal from closing the correct modal if this was a new-item


                    Toaster.makeToast({
                        howToasty: toaster.SUCCESS,
                        message: "Affected Product has been removed."
                    })
                }
                let errorFunction = (xhr) => {
                    console.error(xhr)
                    Toaster.makeToast({
                        howToasty: toaster.DANGER,
                        message: "Failed to remove product. Please contact an administrator with error code: SMG-X2"
                    })
                    this.post_loading = false
                }
                $.ajax({
                    type: "POST",
                    url: post_url,
                    data: data,
                    processData: false,
                    contentType: false,
                    success: successFunction,
                    error: errorFunction,
                })

            },
            closeModal() {
                // Close both modals for simplicity
                if(this.id !== null && this.id !== undefined) {
                    $(`#affected-product-modal-${this.id}`).modal("hide")
                }
                $("#new-affected-product-modal").modal("hide")
                $(".modal-backdrop").remove()
            },
            copyParentSupplierInfo() {
                this.supplier_name = this.parent_supplier_name
                this.supplier_city = this.parent_supplier_city
                this.supplier_state = this.parent_supplier_state
            },
            setProductInfo(data) {
                this.selected_product_id = data.id
                let product = data.detail.product
                let supplier = data.detail.supplier
                let parent_supplier = data.detail.parent_supplier
                this.wrin = product.wrin
                this.wrin_description = product.name
                this.item_id = product.id
                this.product_group = product.prod_group
                this.group_description = product.group_description
                this.parent_wsi = parent_supplier.wsi_number
                this.production_wsi = supplier.wsi_number
                this.parent_supplier_name = parent_supplier.name
                this.parent_supplier_city = parent_supplier.city
                this.parent_supplier_state = parent_supplier.state
                this.supplier_name = supplier.name
                this.supplier_city = supplier.city
                this.supplier_state = supplier.state
            },
            clearProductInfo() {
                this.wrin = ""
                this.wrin_description = ""
                this.item_id = ""
                this.product_group = ""
                this.group_description = ""
                this.parent_wsi = ""
                this.production_wsi = ""
                this.parent_supplier_name = ""
                this.parent_supplier_city = ""
                this.parent_supplier_state = ""
                this.supplier_name = ""
                this.supplier_city = ""
                this.supplier_state = ""
            }
        }))
}

export function initialize_affected_product_set() {
    window.Alpine.data('AffectedProductSet', () => ({
        affected_products: [],
        loading: true,
        init() {
            this.fetchAffectedProducts()
        },
        fetchAffectedProducts() {
            let retrieve_url = $("#affected-products-card-body").attr("data-retrieve-url")
            window.fetch(retrieve_url).then((data) => {
                return data.json()
            }).then((json) => {
                this.affected_products = json["products"]
                this.loading = false
                this.$dispatch('products_retrieved')
            }).catch((error) =>{
                console.log(error)
            })
        },
    }))
}

export function initialize_affected_product_select2() {
    window.Alpine.data('AffectedProductSelect2', (element_id, initial_options=[], multiple=false, value=[], load_options=false) => ({
        multiple: multiple,
        value: value,
        options: initial_options,
        element_id: element_id,
        load_options: load_options,
        loading: true,
        init() {
            if(this.load_options) {
                this.fetchOptions()
            }
            this.bootSelect2()

            $(this.$refs.select).on('change', () => {
                let currentSelection = $(this.$refs.select).select2('data')
                if(currentSelection.length <= 0) {
                    this.value = currentSelection
                    return
                }

                let emit_data = {
                    id: currentSelection[0].id,
                    product: currentSelection[0].product,
                    supplier: currentSelection[0].supplier,
                    parent_supplier: currentSelection[0].parent_supplier,
                }
                // On ajax, add option information for selected val
                if(!this.load_options) {
                    this.options = [emit_data]
                }

                this.$dispatch('product_selected', emit_data)
                this.value = this.multiple
                    ? currentSelection.map(i => i.id)
                    : currentSelection[0].id
            })

            this.$watch('value', () => this.refreshSelect2())
            this.$watch('options', () => this.refreshSelect2())
        },

        fetchRetrieveURL() {
            return `https://${window.location.host}${$(`#${element_id}`).attr("data-retrieve-url")}`
            // return $(`#${element_id}`).attr("data-retrieve-url")
        },
        fetchOptions() {
            let retrieve_url = this.fetchRetrieveURL()
            window.fetch(retrieve_url).then((data) => {
                return data.json()
            }).then((json) => {
                this.options = json["products"]
                this.loading = false
                // this.$dispatch('products_retrieved')
            }).catch((error) => {
                console.log(error)
            })
        },
        bootSelect2 () {
            let selections = this.multiple ? this.value : [this.value]
            let retrieve_url = this.fetchRetrieveURL()
            let select2_settings = {
                templateSelection: this.multiple ? this.select2SelectionTemplateMulti : this.select2SelectionTemplate,
                templateResult: this.select2SelectionTemplate,
                matcher: this.matchCustom,
                placeholder: {
                    id:"-1",
                    text: "------",
                },
                width: "100%",
                multiple: this.multiple,
                data: [{id: -1}].concat(this.options.map(i => ({
                    id: i.id,
                    text: i.product.name,
                    selected: selections.map(i => String(i)).includes(String(i.id)),
                    supplier: i.supplier,
                    parent_supplier: i.parent_supplier,
                    product: i.product,
                    multiple: this.multiple
                })))
            }

            // Either load the data into options or use ajax
            if(!this.load_options) {
                select2_settings["ajax"] = {
                    url: retrieve_url,
                    dataType: "json",
                    delay: 500,
                }
            }

            $(this.$refs.select).select2(select2_settings)
        },
        clearSelection () {
            this.value = []
        },
        refreshSelect2() {
            $(this.$refs.select).select2('destroy')
            this.$refs.select.innerHTML = ''
            this.bootSelect2()
        },
        setOptions(new_options) {
            this.options = new_options
        },
        select2SelectionTemplate (selection) {
            if(selection.id === "-1") {
                return "-----"
            }
            if (!selection.id) {
                return selection.text
            }
            let span = window.$('' +
                '<span class="d-flex">' +
                '<span class="col col-left text-left pr-0"><span class="badge badge-pill badge-primary"></span></span>' +
                '<span class="col col-5 col-center text-left"></span>' +
                '<span class="col col-center2 text-left pr-0"><span class="badge badge-pill badge-secondary"></span></span>' +
                '<span class="col col-7 col-right text-left"></span></span>')
            // let vin = selection.element.dataset.vin
            // let plate = selection.element.dataset.plate
            let supplier_wsi_number = selection.supplier.wsi_number
            let supplier_name = selection.supplier.name
            let wrin = selection.product.wrin
            span.find(".col-left").find('span').text(supplier_wsi_number)
            span.find(".col-center").text(supplier_name)
            span.find(".col-center2").find('span').text(wrin)
            // span.find(".col-center").text(plate)
            span.find(".col-right").text(selection.text)
            return span
        },

        select2SelectionTemplateMulti (selection) {
            if(selection.id === "-1") {
                return "-----"
            }
            if (!selection.id) {
                return selection.text
            }
            let initial_span_class = selection.multiple ? '' : 'd-flex'
            let span = window.$('' +
                `<span class="${initial_span_class}">` +
                '<span class="col col-left text-left pr-0"><span class="badge badge-pill badge-primary mb-1"></span></span>' +
                '<span class="col col-5 col-center text-left"></span>' +
                '<span class="col col-center2 text-left pr-0"><span class="badge badge-pill badge-secondary mb-1"></span></span>' +
                '<span class="col col-7 col-right text-left"></span></span>')
            // let vin = selection.element.dataset.vin
            // let plate = selection.element.dataset.plate
            let supplier_wsi_number = selection.supplier.wsi_number
            let supplier_name = selection.supplier.name
            let wrin = selection.product.wrin
            span.find(".col-left").find('span').text(supplier_wsi_number)
            span.find(".col-center").text(supplier_name)
            span.find(".col-center2").find('span').text(wrin)
            // span.find(".col-center").text(plate)
            span.find(".col-right").text(selection.text)
            return span
        },

        matchCustom(params, data) {
            // Matches supplier_name, supplier_wsi, wrin, or wrin_description
            // If there are no search terms, return all of the data
            if (window.$.trim(params.term) === '') {
                return data;
            }

          // Do not display the item if there is no 'text' property
            if (typeof data.text === 'undefined') {
                return null;
            }

            // `params.term` should be the term that is used for searching
            // `data.text` is the text that is displayed for the data object
            let supplier_wsi = ""
            let supplier_name = ""
            let wrin = ""
            // Check to see if any element is selected.
            // Our placeholder element does not set product information
            // If it did, it would display the null values on page load (select2 single select automatically chooses first option element)
            if(data.element !== undefined && data.id !== "-1") {
                supplier_wsi = `${data.supplier.wsi_number}`
                supplier_name = data.supplier.name.toLowerCase()
                wrin = `${data.product.wrin}`
            }
            if (
                data.text.toLowerCase().indexOf(params.term.toLowerCase()) > -1 ||
                supplier_wsi.indexOf(params.term.toLowerCase()) > -1 ||
                supplier_name.indexOf(params.term.toLowerCase()) > -1 ||
                wrin.indexOf(params.term.toLowerCase()) > -1
            ) {
                let modifiedData = window.$.extend({}, data, true);

                // You can return modified objects from here
                // This includes matching the `children` how you want in nested data sets
                return modifiedData;
            }
            // Return `null` if the term should not be displayed
            return null;
        },
    }))

}

export const CODE_DATE_TYPE_VALUE_MAPPING = {
            "on-before": {
                show_end_dt: false,
                start_dt_text: "Use Thru Date *",
                key_label: "On or Before"
            },
            "to-present": {
                show_end_dt: false,
                start_dt_text: "Use Thru Date *",
                key_label: "To Present"
            },
            "single-date": {
                show_end_dt: false,
                start_dt_text: "Use Thru Date *",
                key_label: "Single Date"
            },
            "date-range": {
                show_end_dt: true,
                start_dt_text: "Use Thru Start Date *",
                key_label: "Date Range",
            },
        }


export function initialize_affected_product_code_date() {
    window.Alpine.data('AffectedProductCodeDate', (response_obj= {
            id: null,
            affected_product_id: null,
            affected_product_mapping_id: null,
            product_item_id: -1,
            start_dt: null,
            end_dt: null,
            has_date: null,
            production_number: "",
            code_type: "",
            product_mapping: {},
            effective_statement_id: null,
    }) => ({
        has_dt: response_obj.has_date,
        start_dt_text: response_obj.code_type === "" || !response_obj.has_date ? "" : CODE_DATE_TYPE_VALUE_MAPPING[response_obj.code_type].start_dt_text,
        show_end_dt: response_obj.code_type === "" || !response_obj.has_date ? "" : CODE_DATE_TYPE_VALUE_MAPPING[response_obj.code_type].show_end_dt,
        date_type: response_obj.code_type,
        production_code: response_obj.production_number,
        code_id: response_obj.id,
        post_loading: false,
        effective_statement_id: response_obj.effective_statement_id,
        start_dt: response_obj.start_dt === null ? null : moment(response_obj.start_dt),
        end_dt: response_obj.end_dt === null ? null: new moment(response_obj.end_dt),
        selected_product: response_obj.affected_product_id,
        start_dt_string: "",

        init () {
            this.$watch('date_type', (value, oldValue) => this.date_type_change_handler(value, oldValue))
            this.initTDEventListeners()
        },
        clearData () {
            this.has_dt = null
            this.start_dt_text = ""
            this.show_end_dt = ""
            this.date_type = ""
            this.production_code = ""
            this.code_id = null
            this.effective_statement_id = null
            this.start_dt = null
            this.end_dt = null
            this.selected_product = null
            this.start_dt_string = ""
        },
        initTDEventListeners() {
            let start_dt_id = "#id_use_thru_dt"
            let end_dt_id = "#id_end_dt"
            if(this.code_id !== null && this.has_dt) {
                start_dt_id += `-${this.code_id}`
                end_dt_id += `-${this.code_id}`
                let val = this.start_dt.format("MM/DD/YYYY")
                this.$nextTick(() => {
                    $(start_dt_id).val(val)
                    if (this.end_dt) {
                        let end_dt_val = this.end_dt.format("MM/DD/YYYY")
                        $(end_dt_id).val(end_dt_val)
                    }
                })

            }
            this.$nextTick(() => {
                $(start_dt_id).on("change.datetimepicker", (event) => {
                this.start_dt = event.target.value
                })
                $(end_dt_id).on("change.datetimepicker", (event) => {
                    this.end_dt = event.target.value
                })
            })

        },
        date_type_change_handler(value, oldValue) {
            let mapped_values = CODE_DATE_TYPE_VALUE_MAPPING[value]
            if (mapped_values) {
                this.show_end_dt = mapped_values.show_end_dt
                this.start_dt_text = mapped_values.start_dt_text
            }

        },
        ajaxPost(event) {
            this.post_loading = true
            event.preventDefault()
            let action_url = $(event.target).attr("action")
            // Our action-url should be the one to save new-items
            // If we have an item ID, this obj already exists and we need to append the item ID to update the existing one
            if (this.code_id !== null && this.code_id !== undefined) {
                action_url += `${this.code_id}/`
            }
            let data = new FormData(event.target)
            // If we select "no" for the question "Does the effective statement have at least one use-thru date" then we are recalling all the product
            if (!this.date_type) {
                data.set("code_type", "all")
            }
            let successFunction = (data) => {
                // Don't use camelcase when dispatching events, DOM will lowercase our event-listener attribute
                this.$dispatch('code_date_added')
                // Call close modal before setting values.
                // Setting the code_id will prevent closeModal from closing the correct modal if this was a new-item
                this.closeModal()
                this.post_loading = false
                // let {product, parent_supplier, supplier, ...remaining} = data

                Toaster.makeToast({
                    howToasty: toaster.SUCCESS,
                    message: "Affected code date information has been saved."
                })
            }
            let errorFunction = (xhr) => {
                console.error(xhr)
                this.post_loading = false
                Toaster.makeToast({
                    howToasty: toaster.DANGER,
                    message: "Failed to add code-date. Please contact an administrator with error code: SMG-X3"
                })
            }
            $.ajax({
                type: "POST",
                url: action_url,
                data: data,
                processData: false,
                contentType: false,
                success: successFunction,
                error: errorFunction,
            })
        },
        closeModal() {
            // Close both modals for simplicity
            if(this.code_id !== null && this.code_id !== undefined) {
                $(`#affected-code-date-modal-${this.code_id}`).modal("hide")
            }
            $("#new-affected-code-date-modal").modal("hide")
            $(".modal-backdrop").remove()
        },
        ajaxDeletePost(event) {
                event.preventDefault()
                let data = new FormData(event.target)
                let post_url = `https://${window.location.host}`
                // Our action-url should be the one to
                if(this.code_id !== null && this.code_id !== undefined && this.effective_statement_id !== null) {
                    post_url += `/stock/effective_statements/${this.effective_statement_id}/delete/affected/codedate/${this.code_id}/`
                }
                this.post_loading = true
                let successFunction = (data) => {
                    this.closeModal()
                    this.post_loading = false
                    // Don't use camelcase when dispatching events, DOM will lowercase our event-listener attribute
                    // We can dispatch product added to refetch the items
                    this.$nextTick(() => {
                        this.$dispatch('code_date_added')
                    })
                    Toaster.makeToast({
                        howToasty: toaster.SUCCESS,
                        message: "Affected Code Date has been removed."
                    })
                }
                let errorFunction = (xhr) => {
                    console.error(xhr)
                    Toaster.makeToast({
                        howToasty: toaster.DANGER,
                        message: "Failed to remove code-date. Please contact an administrator with error code: SMG-X4"
                    })
                    this.post_loading = false
                }
                $.ajax({
                    type: "POST",
                    url: post_url,
                    data: data,
                    processData: false,
                    contentType: false,
                    success: successFunction,
                    error: errorFunction,
                })

            },
    }))
}

export function initialize_affected_code_date_set() {
    window.Alpine.data('AffectedCodeDateSet', () => ({
        affected_code_dates: [],
        date_type_value_mapping: CODE_DATE_TYPE_VALUE_MAPPING,
        loading: true,
        init() {
            this.fetchAffectedCodeDates()
        },
        fetchAffectedCodeDates() {
            let retrieve_url = $("#affected-code-dates-card-body").attr("data-retrieve-url")
            window.fetch(retrieve_url).then((data) => {
                return data.json()
            }).then((json) => {
                let original_code_dates = this.affected_code_dates
                let original_code_date_ids = original_code_dates.map((element) => {
                    return element.id
                })
                this.affected_code_dates = json["code_dates"]
                let all_code_date_ids = json["code_dates"].map((element) => {
                    return element.id
                })
                // Grab the difference between old-data set vs new-data
                let new_code_date_ids = all_code_date_ids.filter(x => !original_code_date_ids.includes(x))
                this.loading = false
                this.$dispatch('code_dates_retrieved')
                // Create TD datepicker for the new code-dates
                if(original_code_date_ids.length) {
                    this.$nextTick(() => {
                        new_code_date_ids.forEach((elem, index) => {
                            let start_dt_id = `id_use_thru_dt-${elem}`
                            let end_dt_id = `id_end_dt-${elem}`
                            let start_dt_jq = $("#" + start_dt_id)
                            let end_dt_jq = $("#" + end_dt_id)
                            let start_dt = moment(new Date(start_dt_jq.val())).format("MM/DD/YYYY")
                            let end_dt = moment(new Date(end_dt_jq.val())).format("MM/DD/YYYY")
                            start_dt_jq.val(start_dt)
                            end_dt_jq.val(end_dt)
                            createDatePickerItem(document.getElementById(start_dt_id))
                            createDatePickerItem(document.getElementById(end_dt_id))
                        })
                    })
                }
            }).catch((error) =>{
                console.log(error)
            })
        },

    }))
}

export function initialize_dc_context() {
    window.Alpine.data('DCContext', () => ({
        distribution_center_id: null,
        is_dc_hub: false,
        setSelectedDC(data) {
            let dc_info = data.detail.dc[0]
            // If "All DC's is selected, id value will be '-1' (str from select input)
            if(dc_info.id === '-1' || dc_info.id === -1) {
                this.distribution_center_id = null
                this.is_dc_hub = false
            } else {
                this.distribution_center_id = dc_info.id
                this.is_dc_hub = dc_info.is_dc_hub
            }
        },
    }))
}

export function initialize_dc_report() {
    window.Alpine.data('DCReport', (initial_url=null, url_path=null, url_template="dc") => ({
        show_report: false,
        url_template: url_template,
        url_path: url_path,
        url: initial_url,
        redirect_url: null,
        init() {
            this.updateURL()
            this.$watch('distribution_center_id', () => this.updateURL())
        },
        updateURL() {
            if(this.distribution_center_id === null) {
                this.show_report = false
                this.url = ""
                return
            }
            this.url = url_template + `/${this.distribution_center_id}/${url_path}`
            $.ajax({
                url: this.url,
                type: "GET",
                dataType: "json",
                success: (data) => {
                    this.show_report = data.has_report
                    if(data.has_report) {
                        this.redirect_url = data.href
                    }
                }
            })
        },
    }))
}

export function initialize_dc_urls() {
    window.Alpine.data('DCUrl', (initial_url=null, url_path=null, url_template="dc", ) => ({
        url_template: url_template,
        url_path: url_path,
        url: initial_url,
        init() {
            this.updateURL()
            this.hideHubInitial()
            this.$watch('distribution_center_id', () => this.updateURL())
            this.$watch("is_dc_hub", (value, oldValue) => {
                if(value !== oldValue) {
                    if(value) {
                        this.hideHubURL()
                    } else {
                        this.showHubURL()
                    }
                }

            })
        },
        updateURL() {
            if(this.distribution_center_id === null) {
                this.url = initial_url
                return
            }
            this.url = url_template + `/${this.distribution_center_id}/${url_path}`

        },
        hideHubInitial() {
            if(this.is_dc_hub) {
                this.hideHubURL()
            }
        },
        hideHubURL() {
            $("#afpr-anchor").addClass("d-none")
            $("#fcs-anchor").addClass("d-none")
            $("#afpr-anchor").removeClass("d-block")
            $("#fcs-anchor").removeClass("d-block")
        },
        showHubURL() {
            $("#afpr-anchor").addClass("d-block")
            $("#fcs-anchor").addClass("d-block")
            $("#afpr-anchor").removeClass("d-none")
            $("#fcs-anchor").removeClass("d-none")
        },
    }))
}
