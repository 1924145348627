import {
  dqs,
  fetchModelsJSON,
  locationsJSON,
  rolesJSON,
  trainersJSON,
  trainingTopicsJSON
} from './utils'

import { toaster } from './toasts'

const $trainingSessionForm = $('#training-session-form')

if ($trainingSessionForm.length) {
  // This JS is also used on the edit Training Session page

  const createNoneSelectedElement = () => {
    const noneSelectedElement = document.createElement('li')
    noneSelectedElement.classList.add('list-group-item', 'fade-in')
    noneSelectedElement.innerText = 'None selected.'
    return noneSelectedElement
  }

  const createListGroupItem = text => {
    const listItem = document.createElement('li')
    listItem.classList.add('list-group-item', 'collapse', 'fade-in-slow')
    listItem.innerHTML = text
    return listItem
  }

  const form = dqs('#training-session-form')
  const conductTrainingDiv = dqs('#conduct-training-div')
  const sessionTimeInput = dqs('#id_session_time')
  const sendNotificationsCheckbox = dqs('#id_send_notifications')
  const trainingSessionFormDiv = dqs('#form-training-session-div')
  const topicsAndPolicies = dqs('#topics-and-policies')
  const labelSendNotifications = dqs('label[for="id_send_notifications"]')
  const cardTitle = dqs('#card-title-id')
  const spanEmployeeCount = dqs('#recommended-employees-count')
  const sessionDate = dqs('#id_session_date')
  const selectedTrainings = dqs('#training-topic-list')
  const selectedPolicies = dqs('#policy-list')
  const localTimeField = dqs('#div_id_session_time')
  const meetingRoomField = dqs('#div_id_meeting_room')
  const recommendEmployeesDiv = dqs('#div-recommend-employees')
  const recommendEmployeesButton = dqs('#recommend-employees-button')
  const submitDraftButton = dqs('#submit-draft')
  const submitFinalButton = dqs('#submit-final')
  const formFilterRecommendedEmployees = dqs('#form-filter-recommended-employees')
  const addRecommendedEmployeesButton = dqs('#add-recommended-employees-button')

  // Container to hold recommended employees
  let recommendedEmployees = null

  // Elements that are hidden when Record Session is selected
  const elementsForScheduleSession = [localTimeField, meetingRoomField, submitDraftButton]

  // Sets for tracking selected items
  const selectedTrainingsSet = new Set()
  const selectedPoliciesSet = new Set()

  const trainer = $('#id_trainer')
  const location = $('#id_location')
  const roles = $('#id_roles')
  const trainings = $('#id_additional_training_topics')
  const policies = $('#id_additional_policies')
  const invited = $('#id_invited')

  const setSessionTimeRequired = isRequired => {
    if (isRequired) {
      sessionTimeInput.setAttribute('required', '')
    } else {
      sessionTimeInput.removeAttribute('required')
    }
  }

  // Set required attribute manually via JS because it has to be optional in forms.py
  setSessionTimeRequired(true)

  // Show/hide training session form VS conduct training table
  const selectScheduleModeButton = dqs('#btn-select-mode-schedule')
  const selectRecordModeButton = dqs('#btn-select-mode-record')
  const selectConductModeButton = dqs('#btn-select-mode-conduct')

  const selectScheduleMode = e => {
    // Schedule Future Training
    conductTrainingDiv.classList.add('hidden')
    trainingSessionFormDiv.classList.remove('hidden')
    setSessionTimeRequired(true)
    topicsAndPolicies.classList.remove('hidden')
    elementsForScheduleSession.forEach(element => element.classList.remove('hidden'))
    recommendEmployeesDiv.style.display = 'grid'
    submitFinalButton.classList.add('hidden')
    cardTitle.innerText = 'Schedule'
    e.target.classList.add('active')
    selectRecordModeButton.classList.remove('active')
    selectConductModeButton.classList.remove('active')
    labelSendNotifications.innerText = 'Notify Employees of Session Date/Time'
    sessionDate.setAttribute("data-use_current", true)
  }

  const selectRecordMode = e => {
    // Record Past Training
    conductTrainingDiv.classList.add('hidden')
    setSessionTimeRequired(false)
    trainingSessionFormDiv.classList.remove('hidden')
    topicsAndPolicies.classList.remove('hidden')
    elementsForScheduleSession.forEach(element => element.classList.add('hidden'))
    recommendEmployeesDiv.style.display = 'none'
    submitFinalButton.classList.remove('hidden')
    cardTitle.innerText = 'Record'
    sendNotificationsCheckbox.checked = false
    e.target.classList.add('active')
    selectScheduleModeButton.classList.remove('active')
    selectConductModeButton.classList.remove('active')
    labelSendNotifications.innerText = 'Request Employee Signatures'
    sessionDate.setAttribute("data-use_current", false)
  }

  const selectConductMode = e => {
    // Conduct Training
    conductTrainingDiv.classList.remove('hidden')
    trainingSessionFormDiv.classList.add('hidden')
    topicsAndPolicies.classList.add('hidden')
    cardTitle.innerText = 'Conduct'
    e.target.classList.add('active')
    selectRecordModeButton.classList.remove('active')
    selectScheduleModeButton.classList.remove('active')
  }

  if (selectScheduleModeButton != null) {
    selectScheduleModeButton.addEventListener('click', selectScheduleMode)
  }
  if (selectRecordModeButton != null) {
    selectRecordModeButton.addEventListener('click', selectRecordMode)
  }
  if (selectConductModeButton != null) {
    selectConductModeButton.addEventListener('click', selectConductMode)
  }

  const setRecommendEmployeesButton = () => {
    // Enable/disable recommend employees button if training topics or policies have been selected
    if (
      (location.select2('data')[0].id && selectedTrainingsSet.size > 0) ||
      (location.select2('data')[0].id && selectedPoliciesSet.size > 0) ||
      (selectedTrainingsSet.size > 0 && location.select2('data')[0].id) ||
      (selectedPoliciesSet.size > 0 && location.select2('data')[0].id)
    ) {
      recommendEmployeesButton.removeAttribute('disabled')
    } else {
      recommendEmployeesButton.setAttribute('disabled', '')
    }
  }

  const getLatest = () => {
    // Clear the UI and the Sets
    selectedTrainings.innerText = ''
    selectedTrainingsSet.clear()
    selectedPolicies.innerText = ''
    selectedPoliciesSet.clear()

    // Add each training topic and policy to their sets, this avoid duplicates between the Select2 fields
    roles.select2('data').forEach(role => {
      // Convert string ID to int to match JSON
      const roleID = parseInt(role.id, 10)

      rolesJSON[roleID]['training_topics'].forEach(training =>
        selectedTrainingsSet.add(training.name)
      )
      rolesJSON[roleID]['policies'].forEach(policy =>
        selectedPoliciesSet.add(policy.name)
      )
    })
    trainings.select2('data').forEach(training => selectedTrainingsSet.add(training.text))
    policies.select2('data').forEach(policy => selectedPoliciesSet.add(policy.text))

    // Display 'None selected.' if relevant
    if (!selectedTrainingsSet.size) {
      selectedTrainings.appendChild(createNoneSelectedElement())
    }
    if (!selectedPoliciesSet.size) {
      selectedPolicies.appendChild(createNoneSelectedElement())
    }

    // Otherwise display the set of topics / policies selected
    if (selectedTrainingsSet.size || selectedPoliciesSet.size) {
      setLatest()
    }

    setRecommendEmployeesButton()
  }

  const getTopicRequiresTrainer = trainingName => {
    for (let key in trainingTopicsJSON) {
      if (trainingTopicsJSON[key].name === trainingName) {
        return trainingTopicsJSON[key].requires_trainer
      }
    }
    return undefined
  }

  const setLatest = () => {
    const sortedTrainings = [...selectedTrainingsSet].sort()
    const sortedPolicies = [...selectedPoliciesSet].sort()
    const trainerId = trainer.select2('data')[0].id

    sortedTrainings.forEach(training => {
      const listItem = createListGroupItem(training)

      const topicRequiresTrainer = getTopicRequiresTrainer(training)

      selectedTrainings.appendChild(listItem)

      if (
        trainerId &&
        topicRequiresTrainer &&
        (trainersJSON[trainerId] === undefined ||
          trainersJSON[trainerId][training] === undefined)
      ) {
        listItem.classList.add('header-with-tooltip-icon')
        listItem.innerHTML =
          listItem.innerHTML +
          `<i tabindex="0" class="fas fa-exclamation-triangle fa-lg float-right" data-toggle="tooltip" data-html="true" title="Trainer has no qualifications for this training topic."></i>`
        $('[data-toggle="tooltip"]').tooltip()
        toaster.makeToast({
          howToasty: toaster.WARNING,
          message: `Trainer is missing qualification for ${training}.`
        })
      }

      // Check qualification expiration
      if (trainerId && trainersJSON[trainerId] && trainersJSON[trainerId][training]) {
        const certExpirationDate = new Date(trainersJSON[trainerId][training])
        const selectedDate = new Date(sessionDate.value)
        if (certExpirationDate < selectedDate) {
          listItem.classList.add('header-with-tooltip-icon')
          const formattedExpirationDate = `${certExpirationDate.getMonth() + 1}/${
            certExpirationDate.getDate() + 1
          }/${certExpirationDate.getFullYear()}`
          listItem.innerHTML =
            listItem.innerHTML +
            `<i tabindex="0" class="fas fa-exclamation-triangle fa-lg float-right" data-toggle="tooltip" data-html="true" title="Trainer qualification expired on ${formattedExpirationDate}."></i>`
          $('[data-toggle="tooltip"]').tooltip()
          toaster.makeToast({
            howToasty: toaster.WARNING,
            message: `Trainer qualification expired on ${formattedExpirationDate}.`
          })
        }
      }
    })

    sortedPolicies.forEach(policy => {
      selectedPolicies.appendChild(createListGroupItem(policy))
    })

    $('.list-group-item').collapse('show')
  }

  // At least one training topic must be selected for form submission
  const checkValidity = event => {
    if (selectedTrainingsSet.size === 0 && selectedPoliciesSet.size === 0) {
      event.preventDefault()
      toaster.makeToast({
        howToasty: toaster.DANGER,
        message: 'You must select at least one role, training topic, or policy.'
      })
      // Button shouldn't be disabled as it will prevent the submit value from going through.
      // $(".dsp-protected-btn").not(":hidden").prop("disabled", false)
      $(".dsp-protected-btn").not(":hidden").next("i").remove()
      $(".dsp-protected").data("submitted", false)
      return false
    }
    return true
  }

  const filterRecommendedEmployees = event => {
    const transferComplete = () => {
      if (xhr.status === 200) {
        const { count, employees } = JSON.parse(xhr.response)

        spanEmployeeCount.parentElement.classList.remove('badge-info', 'badge-danger')
        spanEmployeeCount.parentElement.classList.add('badge-success')
        spanEmployeeCount.innerText = count

        recommendedEmployees = employees

        if (count === 0) {
          addRecommendedEmployeesButton.setAttribute('disabled', '')
        } else {
          addRecommendedEmployeesButton.removeAttribute('disabled')
        }
      } else {
        addRecommendedEmployeesButton.setAttribute('disabled', '')
        spanEmployeeCount.parentElement.classList.remove('badge-info', 'badge-success')
        spanEmployeeCount.parentElement.classList.add('badge-danger')
        spanEmployeeCount.innerText = 'Error'
      }
    }

    event.preventDefault()
    // Needs to be adjusted to work with edit also, not just create
    const uri = dqs('#recommend_employees_url').dataset.smgRecommendEmployeesUrl
    const formData = new FormData(formFilterRecommendedEmployees)
    const [csrftoken, department] = Array.from(formData.entries())

    const trainingIds = new Set()
    const policyIds = new Set()

    roles.select2('data').forEach(role => {
      rolesJSON[role.id]['training_topics'].forEach(training =>
        trainingIds.add(training.id)
      )
      rolesJSON[role.id]['policies'].forEach(policy => policyIds.add(policy.id))
    })
    trainings.select2('data').forEach(training => trainingIds.add(training.id))
    policies.select2('data').forEach(policy => policyIds.add(policy.id))

    const jsonBody = JSON.stringify({
      location: location.select2('data')[0].id,
      department: department[1],
      topics: Array.from(trainingIds),
      policies: Array.from(policyIds)
    })
    const xhr = new XMLHttpRequest()
    xhr.onload = transferComplete
    xhr.open('POST', uri)
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.setRequestHeader('X-CSRFToken', csrftoken[1])
    xhr.send(jsonBody)
  }

  const addRecommendedEmployees = () => {
    setTimeout(() => {
      const inviteeIds = invited.select2('data').reduce((arrOfIds, employee) => {
        arrOfIds.push(parseInt(employee.id, 10))
        return arrOfIds
      }, [])

      recommendedEmployees.forEach(employee => {
        // Skip adding employees already in the invitees field
        if (inviteeIds.includes(employee.id)) {
          return
        }

        // Create a new JS Option object and add it to the invitees field
        const option = new Option(
          `${employee.name_first} ${employee.name_last}`,
          employee.id,
          true,
          true
        )
        invited.append(option).trigger('change')
      })
    }, 333)
  }

  form.addEventListener('submit', checkValidity)
  recommendEmployeesButton.addEventListener('click', filterRecommendedEmployees)
  formFilterRecommendedEmployees &&
    formFilterRecommendedEmployees.addEventListener('submit', filterRecommendedEmployees)
  addRecommendedEmployeesButton.addEventListener('click', addRecommendedEmployees)

  const toggleNotifyEmployeesCheckbox = bool => {
    if (bool) {
      sendNotificationsCheckbox.removeAttribute('disabled')
    } else {
      sendNotificationsCheckbox.setAttribute('disabled', true)
      sendNotificationsCheckbox.checked = false
    }
  }

  const checkNotifyEmployees = e => {
    const locationID = location.select2('data')[0].id
    const loc = locationsJSON[locationID]
    const allowSessionNotifications = loc.allow_session_notifications
    toggleNotifyEmployeesCheckbox(allowSessionNotifications)
  }

  location.on('change', checkNotifyEmployees)
  location.on('change', setRecommendEmployeesButton)
  roles.on('change', getLatest)
  trainings.on('change', getLatest)
  policies.on('change', getLatest)
  trainer.on('change', getLatest)
  $('#id_session_date').on('change', getLatest)

  fetchModelsJSON().then(irrelevant => getLatest())

  const searchParams = new URLSearchParams(window.location.search)
  const mode = searchParams.get('mode')
  if (mode === 'record') {
    selectRecordModeButton.click()
  }
}
