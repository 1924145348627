import {simpleRateMultiplier, valueOrZero} from "../../utils";

let final_cost_form = $("#final-cost-summary-form")

if (final_cost_form.length) {
    let trans_wage_rate = $("#id_trans_coordinator_wage_rate")
    let trans_hours = $("#id_trans_coordinator_hours")
    let trans_total = $("#id_total_trans_cost")
    let warehouse_wage_rate = $("#id_warehouse_coordinator_wage_rate")
    let warehouse_hours = $("#id_ware_coordinator_hours")
    let warehouse_total = $("#id_total_warehouse_coordinator_cost")
    let customer_service_wage_rate = $("#id_customer_service_wage_rate")
    let customer_service_hours = $("#id_customer_service_hours")
    let customer_service_total = $("#id_total_customer_service_cost")
    let misc_wage_rate = $("#id_misc_employee_wage_rate")
    let misc_hours = $("#id_misc_employee_hours")
    let misc_total = $("#id_total_misc_cost")
    let payroll_tax_rate = $("#id_admin_payroll_tax_rate")
    let payroll_tax_total = $("#id_total_admin_taxes")
    let total_admin_cost = $("#id_total_admin_cost")
    let admin_title_cost = $("#admin-title-cost")

    let elems_affecting_total = [
        trans_wage_rate,
        trans_hours,
        warehouse_wage_rate,
        warehouse_hours,
        customer_service_wage_rate,
        customer_service_hours,
        misc_wage_rate,
        misc_hours,
        payroll_tax_rate
    ]


    function handle_trans_hours(event) {
        let total = simpleRateMultiplier(trans_hours, trans_wage_rate)
        trans_total.val(total.toFixed(2))
        return total
    }

    function handle_warehouse_hours(event) {
        let total = simpleRateMultiplier(warehouse_hours, warehouse_wage_rate)
        warehouse_total.val(total.toFixed(2))
        return total
    }

    function handle_customer_service_hours(event) {
        let total = simpleRateMultiplier(customer_service_hours, customer_service_wage_rate)
        customer_service_total.val(total.toFixed(2))
        return total
    }

    function handle_misc_hours(event) {
        let total = simpleRateMultiplier(misc_hours, misc_wage_rate)
        misc_total.val(total.toFixed(2))
        return total
    }

    function handle_total_admin_cost(event) {
        let payroll_tax_num = valueOrZero(payroll_tax_rate.val())
        let payroll_tax_decimal = 0
        if (payroll_tax_num !== 0) {
            payroll_tax_decimal = payroll_tax_num/100
        }
        let trans_cost = handle_trans_hours()
        let warehouse_cost = handle_warehouse_hours()
        let customer_service_cost = handle_customer_service_hours()
        let misc_cost = handle_misc_hours()
        let total_hours_cost = trans_cost + warehouse_cost + customer_service_cost + misc_cost
        let payroll_tax_cost = total_hours_cost * payroll_tax_decimal
        let total_cost = total_hours_cost + payroll_tax_cost

        payroll_tax_total.val(payroll_tax_cost.toFixed(2))
        // Trigger Change event for totalCost.js
        total_admin_cost.val(total_cost.toFixed(2)).trigger("change")
        admin_title_cost.text(`Total Cost: $ ${total_cost.toFixed(2)}`)
        return total_cost
    }

    // Initialize the total values on load
    handle_total_admin_cost(null)

    elems_affecting_total.forEach((elem) => {
        // We don't need to attach the individual event handlers since they are called in handle_total_driver_cost
        elem.on("change", handle_total_admin_cost)
    })
}