import { dqsa } from './utils'

// Setup table header sort arrows
for (let columnHeader of dqsa('.orderable')) {
  const flexDiv = document.createElement('div')
  const styleToApply = columnHeader.classList.contains('text-center')
    ? 'sortable-table-header-center'
    : 'sortable-table-header-left'
  flexDiv.setAttribute('class', styleToApply)
  flexDiv.innerHTML = columnHeader.innerHTML
  columnHeader.innerHTML = ''
  columnHeader.appendChild(flexDiv)
  const nodeToInsertBefore = columnHeader.querySelector('a')

  const sortArrow = document.createElement('i')
  if (columnHeader.classList.contains('asc')) {
    sortArrow.className = 'fas fa-sort-up'
  } else if (columnHeader.classList.contains('desc')) {
    sortArrow.className = 'fas fa-sort-down'
  } else {
    sortArrow.className = 'fal fa-sort'
  }
  flexDiv.insertBefore(sortArrow, nodeToInsertBefore)
}
