import { createSpinner, dqsa } from './utils'

const $testScoreInputs = $('input[name="testscore"]')

if ($testScoreInputs.length) {
  const editButtons = dqsa('.fa-edit')
  const saveButtons = dqsa('.fa-cloud-upload-alt')

  const editMode = event => {
    const tableCell = event.target.parentNode
    const scoreInput = tableCell.querySelector('.training-session-inline-input')
    const pfSelect = tableCell.querySelector('select')
    const span = tableCell.querySelector('span')
    const editButton = tableCell.querySelector('.fa-edit')
    const saveButton = tableCell.querySelector('.fa-cloud-upload-alt')
    if (scoreInput) {
      scoreInput.removeAttribute('disabled')
      scoreInput.classList.remove('hidden')
    }
    if (pfSelect) {
      pfSelect.removeAttribute('disabled', '')
      pfSelect.classList.remove('hidden')
    }
    span.classList.add('hidden')
    editButton.classList.add('hidden')
    saveButton.classList.remove('hidden')
  }

  const save = event => {
    // On save we need to grab the value from the input and write that to the span
    // and send it to the server
    const form = event.target.parentNode
    const scoreInput = form.querySelector('.training-session-inline-input')
    const pfSelect = form.querySelector('select')
    const span = form.querySelector('span')
    const editButton = form.querySelector('.fa-edit')
    const saveButton = form.querySelector('.fa-cloud-upload-alt')

    const onError = form => {
      const spinner = form.querySelector('.spinner-border')
      spinner.remove()

      span.classList.remove('hidden')

      if (scoreInput) {
        scoreInput.classList.add('hidden')
      }
      if (pfSelect) {
        pfSelect.classList.add('hidden')
      }

      const errorIcon = document.createElement('i')
      errorIcon.className = 'fas fa-times-hexagon collapse show'
      form.appendChild(errorIcon)
      setTimeout(transitionIconToEdit, 1500)

      function transitionIconToEdit() {
        $(errorIcon).collapse('hide')
        $(errorIcon).on('hidden.bs.collapse', showEditButton)
      }

      function showEditButton() {
        editButton.classList.remove('hidden')
        $(editButton).collapse('show')
      }
    }

    const onSuccess = form => {
      const spinner = form.querySelector('.spinner-border')
      spinner.remove()

      span.classList.remove('hidden')

      const scoreInput = form.querySelector('.training-session-inline-input')
      const pfSelect = form.querySelector('select')

      if (scoreInput) {
        scoreInput.classList.add('hidden')
        span.innerText = scoreInput.value
      }
      if (pfSelect) {
        pfSelect.classList.add('hidden')
        span.innerText = pfSelect.options[pfSelect.selectedIndex].text
      }

      const successIcon = document.createElement('i')
      successIcon.className = 'fas fa-check-circle collapse show'
      form.appendChild(successIcon)
      setTimeout(transitionIconToEdit, 1500)

      function transitionIconToEdit() {
        $(successIcon).collapse('hide')
        $(successIcon).on('hidden.bs.collapse', showEditButton)
      }

      function showEditButton() {
        editButton.classList.remove('hidden')
        $(editButton).collapse('show')
      }
    }

    const onLoad = form => {
      if (client.status === 204) {
        onSuccess(form)
      } else {
        onError(form)
      }
    }

    const client = new XMLHttpRequest()
    const FD = new FormData(form)
    client.onerror = () => onError(form)
    client.onload = () => onLoad(form)
    client.open('POST', `${window.location.origin}${form.getAttribute('action')}`)
    client.send(FD)

    if (scoreInput) {
      scoreInput.setAttribute('disabled', '')
    }
    if (pfSelect) {
      pfSelect.setAttribute('disabled', '')
    }
    saveButton.classList.add('hidden')

    form.appendChild(
      createSpinner({
        small: true
      })
    )
  }

  for (let button of editButtons) {
    button.addEventListener('click', editMode)
  }

  for (let button of saveButtons) {
    button.addEventListener('click', save)
  }
}
