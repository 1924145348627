import { activateSelectAllCheckbox, countCheckboxes, dqsa, dqs, postForm } from './utils'

import { toaster } from './toasts'

if ($('#training-session-change-date-card').length) {
  const submitButton = dqs('#training-session-change-date-submit-button')


  const onError = event => {
    toaster.makeToast({
      howToasty: toaster.DANGER,
      message: 'Error communicating with the server. Please try again later.'
    })
  }

  const onLoad = event => {
    const client = event.target
    if (client.status == 200 && client.responseURL != '') {
      sessionStorage.setItem('smgToast', `Date successfully changed.`)
      window.location.assign(event.target.responseURL)
    } else {
      toaster.makeToast({
        howToasty: toaster.DANGER,
        message: 'Error while updating records. Please try again later.'
      })
    }
  }

  const handleSubmit = event => {
    const datefield = dqs("#date_approved_update")
    const selectedDate = new Date(datefield.value)

    event.preventDefault()
    if (!isNaN(selectedDate.getTime())) {
      const formData = new FormData(event.target)
      formData.append('new_session_date', selectedDate.toUTCString())
      postForm(event.target.action, formData, onError, onLoad)
    } else {
      toaster.makeToast({
        howToasty: toaster.WARNING,
        message: 'Please select a new date for updating records.'
      })
    }
  }

  $('#training-session-change-date-form').on('submit', handleSubmit)
}

