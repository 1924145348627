import { dqsa, dqs, locationsJSON } from '../utils'

import { toaster } from '../toasts'

const affectedDCForm = $('#affected-dc-form')

if (affectedDCForm.length) {
  const form = dqs('form')
  const selectAllButton = dqs('#id-btn-select-all')
  const clearButton = dqs('#id-btn-clear')
  const selectDCField = $('#id_selected_dcs')

  const clearDCs = (event) => {
    selectDCField.val(null).trigger("change")
  }

  const selectAllDCs = (event) => {
    const append_spinner = `<i id="appended_spinner" class="fas fa-spin fa-spinner ml-2"></i>`
    $("#id-btn-select-all").after(append_spinner)
    fetch(window.location.origin + "/stock/api/v1/get/dcs/")
      .then(response => {
        return response.json()
      })
      .then(json => {
        const appended_spinner = $("#appended_spinner")
        if(append_spinner.length) {
          appended_spinner.remove()
        }
        // Remove all selected options. We will be replacing them.
        selectDCField.find("option").remove()
        json.distribution_centers.forEach((dc) => {
          const option = new Option(`${dc.name} - ${dc.number}`, dc.dc_date_value_id, true, true)
          selectDCField.append(option).trigger('change')
        })
      })
  }

  // function to remove the current selection count and update it
  function display_selected_count() {
    $("#selected_count").remove()
    let selected_count = $("#id_selected_dcs option:selected").length
    let span_selected = `<h5 id="selected_count" class="d-inline-block float-right">Selected: <span class="badge badge-warning">${selected_count}</span></h5>`
    $("#div_id_selected_dcs > label").after(span_selected)
  }
  // Handle initial page load
  display_selected_count()

  // Update the count on change
  $("#id_selected_dcs").on("change", () => {
    display_selected_count()
  })

  selectAllButton.addEventListener('click', selectAllDCs)
  clearButton.addEventListener('click', clearDCs)
}
