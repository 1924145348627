import {
  activateSelectAllCheckbox,
  countCheckboxes,
  createSpinner,
  dqsa,
  dqs,
  hideElement,
  postForm,
  showElement
} from './utils'

import {attachSoloFormHandler} from "./submitSoloForm";

import { toaster } from './toasts'

const buttonApprovalModeNone = $('#button-approval-mode-none')
const openAssignmentsReportModeSelector = $('#div-oar-mode-selector')
const expiredAssignmentSelector = ('#js-hook-oar-expired-training-solo')

if(expiredAssignmentSelector.length) {
  attachSoloFormHandler()
}

if (openAssignmentsReportModeSelector.length) {
  let shouldSubmitAll = false
  let countChecked = null
  const buttonOneByOneApproval = dqs('#button-one-by-one-approval')
  const buttonBulkApproval = dqs('#button-bulk-approval')
  const buttonBulkRecord = dqs("#button-bulk-record")
  const bulkApprovalSubmitButton = document
    .querySelector('#record-past-training-form')
    .querySelector('button[type="submit"]')
  const bulkApprovalSubmitButtonCount = dqs('#approve-btn-count')
  const checkboxes = $('[id^="bulk-approval-checkbox-"]')
  const totalAssignmentsCount = dqs('#total-assignments-count').dataset
    .smgTotalAssignmentsCount
  const countCheckedAssignemnts = () => {
    countChecked = countCheckboxes(checkboxes)
    if (countChecked == 0) {
      bulkApprovalSubmitButton.setAttribute('disabled', '')
    } else {
      bulkApprovalSubmitButton.removeAttribute('disabled')
    }
    bulkApprovalSubmitButtonCount.innerText = ` ${countChecked} Assignment${
      countChecked != 1 ? 's' : ''
    }`
  }
  const setSubmitAll = () => {
    bulkApprovalSubmitButton.removeAttribute('disabled')
    bulkApprovalSubmitButtonCount.innerText = ` ${totalAssignmentsCount} Assignments`
  }
  countCheckedAssignemnts()
  shouldSubmitAll = false
  checkboxes.each((idx, c) =>
    c.addEventListener('click', e => {
      countCheckedAssignemnts()
    })
  )
  const $selectAllCheckbox = $('#bulk-approval-select-all-checkbox')
  if ($selectAllCheckbox.length) {
    activateSelectAllCheckbox($selectAllCheckbox, checkboxes)
    $selectAllCheckbox.on('click', e => {
      if (e.target.checked) {
        shouldSubmitAll = window.confirm(
          `Would you like to select all ${totalAssignmentsCount} assignments in this report?`
        )
      } else {
        shouldSubmitAll = false
      }
      shouldSubmitAll ? setSubmitAll() : countCheckedAssignemnts()
    })
  }
  const hideFilterCard = () => {
    hideElement(dqs('#div-filter-card'))
    dqs('#div-card-table').classList.remove('col-lg-9')
    dqs('#div-card-table').classList.add('col-lg-12')
  }
  const showApprovalModeNone = () => {
    showElement(dqs('#div-filter-card'))
    dqs('#div-card-table').classList.remove('col-lg-12')
    dqs('#div-card-table').classList.add('col-lg-9')
    dqsa('.js-hook-oar-one-by-one-mode').forEach(element => hideElement(element))
    dqsa('.js-hook-oar-hire-date').forEach(element => showElement(element))
    hideElement(dqs('#div-bulk-approval-form'))
    hideElement(dqs('#div-record-button'))
    dqsa('.js-hook-oar-bulk-mode').forEach(element => hideElement(element))
  }
  const showApprovalModeOneByOne = () => {
    hideFilterCard()
    hideElement(dqs('#div-bulk-approval-form'))
    hideElement(dqs('#div-record-button'))
    dqsa('.js-hook-oar-one-by-one-mode').forEach(element => showElement(element))
    dqsa('.js-hook-oar-hire-date').forEach(element => hideElement(element))
    dqsa('.js-hook-oar-bulk-mode').forEach(element => hideElement(element))
  }

  const showApprovalModeBulk = () => {
    hideFilterCard()
    hideElement(dqs('#div-record-button'))
    showElement(dqs('#div-bulk-approval-form'))
    dqsa('.js-hook-oar-one-by-one-mode').forEach(element => hideElement(element))
    dqsa('.js-hook-oar-hire-date').forEach(element => showElement(element))
    dqsa('.js-hook-oar-bulk-mode').forEach(element => showElement(element))
  }

  const showApprovalModeRecord = () => {
    hideFilterCard()
    hideElement(dqs('#div-bulk-approval-form'))
    showElement(dqs('#div-record-button'))
    dqsa('.js-hook-oar-one-by-one-mode').forEach(element => hideElement(element))
    dqsa('.js-hook-oar-hire-date').forEach(element => showElement(element))
    dqsa('.js-hook-oar-bulk-mode').forEach(element => showElement(element))
  }

  buttonApprovalModeNone.on('click', showApprovalModeNone)
  buttonOneByOneApproval.addEventListener('click', showApprovalModeOneByOne)
  buttonBulkApproval.addEventListener('click', showApprovalModeBulk)
  buttonBulkRecord.addEventListener('click', showApprovalModeRecord)

  // JS for RecordPastTrainingForm
  const recordPastTrainingForm = dqs('#record-past-training-form')
  if (recordPastTrainingForm != null) {
    const handleSubmit = event => {
      event.preventDefault()
      const dateTrained = new Date(event.target.querySelector('#id_date_trained').value)
      const today = new Date()
      if (dateTrained < today) {
        const spinner = createSpinner({
          small: true
        })
        const submitButton = dqs('#bulk-approval-submit-button')
        submitButton.innerHTML = ''
        submitButton.appendChild(spinner)
        const assignmentIds = []
        const checkedCheckboxes = dqsa('input[id^="bulk-approval-checkbox-"]:checked')
        checkedCheckboxes.forEach(checkbox => assignmentIds.push(checkbox.dataset.smgId))

        const formData = new FormData(event.target)
        formData.append('assignment_ids', assignmentIds)
        formData.append('should_approve_all', shouldSubmitAll)

        const onError = event => {
          toaster.makeToast({
            howToasty: toaster.DANGER,
            message: 'Error communicating with the server. Please try again later.'
          })
        }

        const onLoad = event => {
          const client = event.target
          if (client.status == 200 && client.responseURL != '') {
            const countChecked = countCheckboxes(checkboxes)
            const suffix = countChecked > 1 ? 's' : ''
            sessionStorage.setItem(
              'smgToast',
              `${countChecked} assignment${suffix} approved.`
            )
            window.location.assign(event.target.responseURL)
          } else {
            const errorIcon = document.createElement('i')
            errorIcon.className = 'fas fa-times-hexagon'
            spinner.replaceWith(errorIcon)
            toaster.makeToast({
              howToasty: toaster.DANGER,
              message: 'Error while approving assignments. Please try again later.'
            })
          }
        }

        postForm(event.target.action, formData, onError, onLoad)
      } else {
        toaster.makeToast({
          howToasty: toaster.WARNING,
          message: 'Date trained must be in the past.'
        })
      }
    }
    recordPastTrainingForm.addEventListener('submit', handleSubmit)
  }

}
