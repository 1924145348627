import {createSpinner, dqsa, postForm} from "./utils";
import {toaster} from "./toasts";

export const attachSoloFormHandler = () => {
    const fileFields = dqsa('.custom-file-input')
    const hasFile = event => {
        if (event.target.files.length) {
            const files = event.target.files
            const filenames = []
            for (let curr_file of files) {
                filenames.push(curr_file.name)
                if (curr_file.name.length > 255) {
                  toaster.makeToast({
                    howToasty: toaster.WARNING,
                    message: 'That file name is too long. Please rename the file.'
                  })
                  const fileInput = event.target
                  fileInput.value = null
                  const nextSibling = fileInput.nextElementSibling
                  if (nextSibling != null) {
                    nextSibling.innerText = 'Choose file'
                  }
                } else {
                  const new_label = filenames.join(', ')
                  const fileInput = event.target
                  const nextSibling = fileInput.nextElementSibling
                  nextSibling.innerText = new_label
                }
            }
        }
    }

    for (let field of fileFields) {
        field.addEventListener('change', hasFile)
    }

    const submitSoloForm = e => {
        e.preventDefault()
        // Grab form data before disabling fields
        const formData = new FormData(e.target)
        const parentElement = e.target.parentElement
        const saveButton = parentElement.querySelector('button')
        const fileInputElement = parentElement.querySelector('input[name="document"]')
        const dateTrainedElement = parentElement.querySelector('.datepicker')
        const formElements = [dateTrainedElement, saveButton]
        if (fileInputElement != null) {
            formElements.push(fileInputElement)
        }
        formElements.forEach(element => element.setAttribute('disabled', ''))
        const spinner = createSpinner({
            small: true
        })
        const dateTrained = new Date(dateTrainedElement.value)
        const today = new Date()
        if (dateTrained < today) {
            saveButton.replaceWith(spinner)
            const onError = event => {
                const errorIcon = document.createElement('i')
                errorIcon.className = 'fas fa-times-hexagon collapse show'
                spinner.replaceWith(errorIcon)
                toaster.makeToast({
                    howToasty: toaster.DANGER,
                    message: 'Error while approving that assignment. Please try again later.'
                })
            }

            const onLoad = event => {
                if (event.target.status == 204) {
                    const successIcon = document.createElement('i')
                    successIcon.className = 'fas fa-check-circle'
                    spinner.replaceWith(successIcon)
                    toaster.makeToast({
                        howToasty: toaster.SUCCESS,
                        message: 'Assignment approved.'
                    })
                } else {
                    const errorIcon = document.createElement('i')
                    errorIcon.className = 'fas fa-times-hexagon'
                    spinner.replaceWith(errorIcon)
                    toaster.makeToast({
                        howToasty: toaster.DANGER,
                        message: 'Error while approving that assignment. Please try again later.'
                    })
                }
            }

            postForm(e.target.action, formData, onError, onLoad)
        } else {
            formElements.forEach(element => element.removeAttribute('disabled'))
            toaster.makeToast({
                howToasty: toaster.WARNING,
                message: 'Date trained must be in the past.'
            })
        }
    }

    $(document).on('submit', '[id^="form-oar-submit-solo-force-approval-"]', submitSoloForm)
}