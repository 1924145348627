import { activateSelectAllCheckbox } from './utils'

const bulkEditEmployeesForm = $('#employee-bulk-edit-step-2-form1')

if (bulkEditEmployeesForm.length) {
  const selectAllEmployeesCheckbox = $('#select-all-employees-checkbox')
  const employeeCheckboxes = $('input[type="checkbox"][name="e"]:not([disabled])')

  if (selectAllEmployeesCheckbox.length && employeeCheckboxes.length) {
    activateSelectAllCheckbox(selectAllEmployeesCheckbox, employeeCheckboxes)
  }
}
