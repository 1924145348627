import {getCookie, parseTimeInput, simpleRateMultiplier, valueOrZero} from "../../utils";

let final_disposition_form = $("#final-disposition-form")

if (final_disposition_form.length) {
    const cases_on_hold = $("#id_num_cases_on_hold")
    const cases_recovered = $("#id_num_cases_recovered_from")
    const total_cases_for_credit = $("#id_cases_available_for_credit")

    const cases_returned = $("#id_cases_returned_to_supplier")
    const cases_destroyed = $("#id_cases_destroyed")
    const total_cases_supplier_credit = $("#id_total_cases_supplier_credit")

    const total_dc_product_cost = $("#id_total_dc_product_cost")
    const total_restaurant_credit = $("#id_total_restaurant_credit")
    const total_supplier_credit = $("#id_total_supplier_credit")

    const date_cases_returned = $("#id_date_cases_returned")
    const time_cases_returned = $("#id_time_cases_returned")
    const duration_cases_returned = $("#id_cases_returned_duration")

    const date_cases_destroyed = $("#id_date_cases_destroyed")
    const time_cases_destroyed = $("#id_time_cases_destroyed")
    const duration_cases_destroyed = $("#id_cases_destroyed_duration")


    const effectiveStatementID = $('#id_effective_statement_id')
    const distributionCenterID = $('#id_distribution_center_id')



    function handle_total_cases_for_supplier_credit(event) {
        let total = valueOrZero(parseFloat(cases_returned.val())) + valueOrZero(parseFloat(cases_destroyed.val()))
        total_cases_supplier_credit.val(total.toFixed(2))
        return total
    }

    cases_returned.on("change", handle_total_cases_for_supplier_credit)
    cases_destroyed.on("change", handle_total_cases_for_supplier_credit)

    handle_total_cases_for_supplier_credit()


    let successFunction = (data, elem) => {
        let result = data["time_diff"]
        if(result) {
            elem.val(result)
        }
    }
    let successReturn = (data) => {
        successFunction(data, duration_cases_returned)
    }
    let successDestroyed = (data) => {
        successFunction(data, duration_cases_destroyed)
    }
    let errorFunction = (xhr) => {
        console.error(xhr)
    }

    function fetch_duration(date_val, time_element, successFunction) {
        // If we don't have one of the values, we can't combine them to create our moment.js object to pass as data
        if(!date_val || !time_element.val()) {
            return
        }
        // Grab the values of our date/timepickers
        let dummy_date = new Date(date_val)
        // Grab the time, split it into hours, minutes, ampm
        let {hours, minutes} = parseTimeInput(time_element)
        // Combine the time with the date
        dummy_date.setHours(hours)
        dummy_date.setMinutes(minutes)

        let date_obj = new Date(Date.UTC(dummy_date.getFullYear(), dummy_date.getMonth(), dummy_date.getDate(), dummy_date.getHours(), dummy_date.getMinutes()))
        let data = new FormData()
        // Combine the time with the date
        let csrftoken = getCookie("smgcsrftoken")
        data.set("dt", date_obj.toISOString())
        data.set("csrfmiddlewaretoken", csrftoken)
        let action_url = `/stock/api/v1/dc/${distributionCenterID.val()}/effective_statement/${effectiveStatementID.val()}/calculate/duration/`
        $.ajax({
            type: "POST",
            url: action_url,
            data: data,
            processData: false,
            contentType: false,
            success: successFunction,
            error: errorFunction,
        })
    }

    // The handler checks for both date and time value before making an ajax request
    date_cases_returned.on("change.datetimepicker", (old_date, new_date) => {
        fetch_duration(date_cases_returned.val(), time_cases_returned, successReturn)
    })
    time_cases_returned.on("change.datetimepicker", (old_date, new_date) => {
        fetch_duration(date_cases_returned.val(), time_cases_returned, successReturn)
    })

    date_cases_destroyed.on("change.datetimepicker", (old_date, new_date) => {
        fetch_duration(date_cases_destroyed.val(), time_cases_destroyed, successDestroyed)
    })
    time_cases_destroyed.on("change.datetimepicker", (old_date, new_date) => {
        fetch_duration(date_cases_destroyed.val(), time_cases_destroyed, successDestroyed)
    })

}