import {
  dqs,
  fetchModelsJSON,
  locationsJSON,
  rolesJSON,
  trainersJSON,
  trainingTopicsJSON
} from './utils'


$(".test-attempt-form").on("submit", (event) => {
  let form_inputs = $(".test-attempt-form").find(':input:not([type="hidden"]):not([type=button]):not([type=submit]):not([type=reset])')
  let json = {
    "test_id": $(".test-attempt-form").data("test-id"),
    "question_ids": [],
  }
  form_inputs.each((index, value) => {
    if($(value).prop("checked")) {
      let jqValue = $(value)
      json[jqValue.attr("name")] = {
        "question_id": jqValue.data('question-id'),
        "selected_option_id": jqValue.data("option-id"),
        "value": jqValue.val()
      }
      json["question_ids"].push(jqValue.data('question-id'))
    }
  })
  $("#test-json-values").val(JSON.stringify(json))
  console.log("submitted");
})