let final_cost_form = $("#final-cost-summary-form")

if (final_cost_form.length) {
    let num_phone_calls = $("#id_num_phone_calls")
    let price_per_call = $("#id_price_per_call")
    let total_phone_cost = $("#id_total_phone_cost")

    let outsource_delivery_charge = $("#id_outsourcing_delivery_charge")
    let freight_charge_to_dc = $("#id_freight_charge_to_dc")
    let handling_fees = $("#id_handling_fees")

    let other_cost = $("#id_other_cost")
    let data_forms_cost = $("#id_data_forms_cost")
    let total_associated_cost = $("#id_total_associated_cost")
    let total_title_cost = $("#associated-title-cost")

    function handle_phone_cost(event) {
        let num_phone_calls_val = parseInt(num_phone_calls.val())
        let price_per_call_val = parseFloat(price_per_call.val())

        num_phone_calls_val = num_phone_calls_val ? num_phone_calls_val : 0
        price_per_call_val = price_per_call_val ? price_per_call_val : 0

        let total_phone_cost_val = (num_phone_calls_val * price_per_call_val)
        total_phone_cost.val(total_phone_cost_val.toFixed(2))
        return total_phone_cost_val
    }

    function handle_total_cost(event) {
        let total_phone_cost_val = handle_phone_cost(null)
        let outsourcing_cost = parseFloat(outsource_delivery_charge.val())
        let freight_charge = parseFloat(freight_charge_to_dc.val())
        let handling_fee_val = parseFloat(handling_fees.val())
        let other_cost_val = parseFloat(other_cost.val())
        let data_forms_cost_val = parseFloat(data_forms_cost.val())

        outsourcing_cost = outsourcing_cost ? outsourcing_cost : 0
        freight_charge = freight_charge ? freight_charge : 0
        handling_fee_val = handling_fee_val ? handling_fee_val : 0
        other_cost_val = other_cost_val ? other_cost_val : 0
        data_forms_cost_val = data_forms_cost_val ? data_forms_cost_val : 0

        let total_associated_cost_val = (outsourcing_cost + freight_charge + handling_fee_val + other_cost_val + data_forms_cost_val + total_phone_cost_val).toFixed(2)
        // Trigger Change event for totalCost.js
        total_associated_cost.val(total_associated_cost_val).trigger("change")
        total_title_cost.text(`Total Cost: $ ${total_associated_cost_val}`)
    }

    // Initialize Total Values
    handle_phone_cost()
    handle_total_cost()

    num_phone_calls.on("change", handle_phone_cost)
    price_per_call.on("change", handle_phone_cost)

    num_phone_calls.on("change", handle_total_cost)
    price_per_call.on("change", handle_total_cost)
    outsource_delivery_charge.on("change", handle_total_cost)
    freight_charge_to_dc.on("change", handle_total_cost)
    handling_fees.on("change", handle_total_cost)
    other_cost.on("change", handle_total_cost)
    data_forms_cost.on("change", handle_total_cost)
}