import {simpleRateMultiplier, valueOrZero} from "../../utils";

let final_cost_form = $("#final-cost-summary-form")

if (final_cost_form.length) {

    let cases_on_hold_all = $("#cases_on_hold")
    let total_case_cost_all = $("#total_cost_all")

    let cases_on_hold_products = $(".product-js.case_count")
    let price_per_case_products = $(".product-js.price_per_case")
    let total_cost_products = $(".product-js-total")
    let product_cost_title = $("#product-title-cost")

    function handle_case_cost_all(event) {
        let total = total_all_product_costs()
        product_cost_title.text(`Total Cost: $ ${total.toFixed(2)}`)
        total_case_cost_all.val(total.toFixed(2))
        return total
    }

    function total_all_product_costs(event) {
        let total_val = 0
        $(".product-js-total").each((index, element) => {
            let element_val = parseFloat(valueOrZero($(element).val()))
            total_val += element_val
        })
        return total_val

    }

    function handle_case_cost_products(event) {
        if(event === null) {
            // trigger the change with an event attached
            cases_on_hold_products.trigger("change")
            return
        }
        let product_item_id = $(event.target).data("product-id")
        let cases_on_hold_jq = cases_on_hold_products.filter(`[data-product-id=${product_item_id}]`)
        let price_per_case_jq = price_per_case_products.filter(`[data-product-id=${product_item_id}]`)
        let total = simpleRateMultiplier(cases_on_hold_jq, price_per_case_jq)
        let total_cost_jq = total_cost_products.filter(`[data-product-id=${product_item_id}]`)
        // Trigger Change event for totalCost.js
        total_cost_jq.val(total.toFixed(2)).trigger("change")
        handle_case_cost_all(event)
    }

    // Attach event listeners prior to initialization
    cases_on_hold_products.on("change", handle_case_cost_products)
    price_per_case_products.on("change", handle_case_cost_products)


    handle_case_cost_products(null)
    handle_case_cost_all(null)




}