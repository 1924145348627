import { toaster } from './toasts'
import {
  faFiveIcons,
} from '@eonasdan/tempus-dominus/dist/plugins/fa-five';

export let locationsJSON = null
export let rolesJSON = null
export let trainersJSON = null
export let trainingTopicsJSON = null

export const dqs = selectors => {
  return document.querySelector(selectors)
}

export const dqsa = selectors => {
  return document.querySelectorAll(selectors)
}

export const hideElement = element => {
  element.classList.add('hidden')
}

export const showElement = element => {
  element.classList.remove('hidden')
}

export const toggleElementVisibility = element => {
  if($(element).css('visibility') === 'hidden') {
    $(element).css('visibility', '')
  }
  else {
    $(element).css('visibility', 'hidden')
  }
}

export const createSpinner = ({ small = false }) => {
  const spinner = document.createElement('span')
  spinner.classList.add('spinner-border')
  if (small) {
    spinner.classList.add('spinner-border-sm')
  }
  spinner.setAttribute('role', 'status')
  spinner.setAttribute('aria-hidden', 'true')
  return spinner
}

export const animateShowElement = element => {
  element.style.maxHeight = '20rem'
  element.style.marginBottom = '1rem'
}

export const animateHideElement = element => {
  element.style.maxHeight = '0rem'
  element.style.marginBottom = '0rem'
}

export const paramsToObject = entries => {
  let result = {}
  for (let entry of entries) {
    const [key, value] = entry
    if (value != '') {
      if(key in result) {
        // This key already exists in the object, meaning we have a multi-select
        let existing_val = result[key]
        if(Array.isArray(existing_val)) {
          // We have already encountered this key at least twice
          existing_val.push(value)
          result[key] = existing_val
        } else {
          // This is the second time this key has been encountered. Convert it to an array of values
          result[key] = [existing_val, value]
        }
      } else {
        result[key] = value
      }

    }
  }
  return result
}

export const postForm = (action, formData, onError = null, onLoad = null) => {
  const client = new XMLHttpRequest()
  const csrftoken = document.cookie.match(`(^|;) ?smgcsrftoken=([^;]*)(;|$)`)[2]
  client.onerror = onError
  client.onload = onLoad
  client.open('POST', action)
  client.setRequestHeader('X-CSRFToken', csrftoken)
  client.send(formData)
}

export const countCheckboxes = nodeList => {
  return Array.from(nodeList).reduce((prev, curr) => (curr.checked ? prev + 1 : prev), 0)
}

export const activateSelectAllCheckbox = ($selectAllCheckbox, $childCheckboxes) => {
  $selectAllCheckbox.on('click', e => {
    $childCheckboxes.prop('checked', e.target.checked)
  })
  $childCheckboxes.each((idx, checkbox) => {
    checkbox.addEventListener('click', e => {
      if ($selectAllCheckbox.prop('checked') && !e.target.checked) {
        $selectAllCheckbox.prop('checked', false)
      }
    })
  })
}

export const fetchModelsJSON = async () => {
  const origin = window.location.origin
  const $locationsEndpointDiv = $('#locations-endpoint')
  const $rolesEndpointDiv = $('#roles-endpoint')
  const $trainersEndpointDiv = $('#trainers-endpoint')
  const $trainingTopicsEndpointDiv = $('#training-topics-endpoint')
  try {
    if ($locationsEndpointDiv.length) {
      const locationsURL = origin + $locationsEndpointDiv.data('smg-endpoint')
      const locationsData = await fetch(locationsURL)
      locationsJSON = await locationsData.json()
    }
    if ($rolesEndpointDiv.length) {
      const rolesURL = origin + $rolesEndpointDiv.data('smg-endpoint')
      const rolesData = await fetch(rolesURL)
      rolesJSON = await rolesData.json()
    }
    if ($trainersEndpointDiv.length) {
      const trainersURL = origin + $trainersEndpointDiv.data('smg-endpoint')
      const trainersData = await fetch(trainersURL)
      trainersJSON = await trainersData.json()
    }
    if ($trainingTopicsEndpointDiv.length) {
      const trainingTopicsURL = origin + $trainingTopicsEndpointDiv.data('smg-endpoint')
      const trainingTopicsData = await fetch(trainingTopicsURL)
      trainingTopicsJSON = await trainingTopicsData.json()
    }
  } catch (error) {
    captureError(error)
  }
}

export const captureError = error => {
  Sentry.captureException(error)
  toaster.makeToast({
    howToasty: toaster.DANGER,
    message: 'Something has gone wrong. Please try again later.'
  })
}

export const getCookie = name => {
  let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}


export const createDatePickerItem = (elem, keepOpen=false) => {
  return new window.tempusDominus.TempusDominus(elem, {
    display: {
      keepOpen: keepOpen,
      components: {
        clock: false,
      },
      buttons: {
        clear: true,
        close: true,
      },
      icons: window.tempusDominus.plugins.fa_five.faFiveIcons
    },
    localization: {
      format: 'L',
    },
  })
}

export const createTimePickerItem = (elem) => {
  let stepping = $(elem).data("stepping")
  // If stepping is not specified, set stepping to 1 minute
  stepping = stepping === undefined ? 1: stepping
  return new window.tempusDominus.TempusDominus(elem, {
      stepping: stepping,
      display: {
        viewMode: 'clock',
        components: {
          decades: false,
          year: false,
          month: false,
          date: false,
          hours: true,
          minutes: true,
          seconds: false,
        },
        buttons: {
          clear: true,
          close: true,
        },
        icons: window.tempusDominus.plugins.fa_five.faFiveIcons
      },
      localization: {
        hourCycle: "h12",
        format: "hh:mm T",
      },

    });
}

export const createDateTimePickerItem = (elem) => {
  const picker =  new window.tempusDominus.TempusDominus(elem, {
    allowInputToggle: false,
    localization: {
      format: "MM/dd/yyyy hh:mm T"
    },
    display: {
      buttons: {
        clear: true,
        close: true,
      },
      icons: window.tempusDominus.plugins.fa_five.faFiveIcons
    }
  })

  let jq_elem = $(elem)
  if(jq_elem.data("load-initial")) {
    let initial_val = jq_elem.val()
    if (initial_val !== "") {
      let initial_datetime = new window.tempusDominus.DateTime(initial_val)
      picker.dates.setValue(initial_datetime)
    }

  }
  return picker
}

export const valueOrZero = (value) => {
  return value ? value : 0
}

export const simpleRateMultiplier = (jq_element1, jq_element2) => {
  let value1 = valueOrZero(parseFloat(jq_element1.val()))
  let value2 = valueOrZero(parseFloat(jq_element2.val()))
  let result_value = value1 * value2
  return result_value
}

export const parseTimeInput = (jq_element) => {
  // Grab the time, split it into hours, minutes, ampm
  let time_val = jq_element.val()
  let split_vals_1 = time_val.split(":")
  let hours = parseInt(split_vals_1[0])
  let split_vals_2 = split_vals_1[1].split(" ")
  let minutes = parseInt(split_vals_2[0])
  let ampm = split_vals_2[1]
  // Correctly determine hours val based on AM/PM
  if(ampm.toLowerCase() == "pm" && hours <12) {
      hours += 12
  }
  else if(ampm.toLowerCase() == "am" && hours == 12) {
      hours = 0
  }
  return {"hours": hours, "minutes": minutes}
}
