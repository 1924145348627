import { dqsa } from './utils'

import { toaster } from './toasts'

// Setup custom-file-input label changing on file selection
// and setup clearing field and displaying warning toast
// when filesize is over the limit
dqsa('.custom-file-input').forEach(input => {
  input.addEventListener('change', e => {
    const fileInput = e.target
    const fileInputWasCleared = fileInput.files.length === 0
    const nextSibling = fileInput.nextElementSibling

    if (fileInputWasCleared) {
      nextSibling.innerText = 'Choose file'
    } else {
      const filesize = (fileInput.files[0].size / 1000000).toFixed(4)

      if (filesize > 25) {
        toaster.makeToast({
          howToasty: toaster.WARNING,
          message: 'Document uploads must be smaller than 25 megabytes.'
        })
        fileInput.value = null
        if (nextSibling != null) {
          nextSibling.innerText = 'Choose file'
        }
      } else {
        const fileName = e.target.files[0].name
        nextSibling.innerText = fileName
      }
    }
  })
})
