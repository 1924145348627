import {hideElement, showElement, createTimePickerItem, createDateTimePickerItem, createDatePickerItem} from './modules/utils'
import initialize_alpine from './modules/stock_recovery/alpineUtil'
import {toaster} from "./modules/toasts";


initialize_alpine()

// Ask to confirm on clicking elements with ask-to-confirm class
$(document).on('click', '.ask-to-confirm', e => {
  if (!window.confirm('Are you sure?')) {
    e.preventDefault()
  }
})

import('./modules/tableColumnHeaders')

// Setup show/hide icon and text toggling
$(document).on('click', '[data-toggler="show-hide"]', event => {
  const button = event.currentTarget
  const hideButtonDiv = button.querySelector('.hide-toggle-button')
  const showButtonDiv = button.querySelector('.show-toggle-button')

  if (hideButtonDiv.classList.contains('hidden')) {
    showElement(hideButtonDiv)
    hideElement(showButtonDiv)
  } else {
    hideElement(hideButtonDiv)
    showElement(showButtonDiv)
  }
})

window.addEventListener('load', event => {
  // Activate tooltips
  $('[data-toggle="tooltip"]').tooltip({ delay: { show: 333, hide: 100 } })
  const clipboard = new ClipboardJS('[data-clipboard-text]')

  clipboard.on('success', e => {
    toaster.makeToast({ howToasty: 't-success', message: 'Copied to clipboard.' })

    e.clearSelection()
  })

  clipboard.on('error', e => {
    toaster.makeToast({ howToasty: 't-danger', message: 'Unable to copy to clipboard.' })
  })

  // jQuery plugin to prevent double submission of forms
  jQuery.fn.preventDoubleSubmission = function() {
    $(this).on('submit', function(e){
      var $form = $(this);

      if ($form.data('submitted') === true) {
        // Previously submitted - don't submit again
        e.preventDefault();
      } else {
        // Mark it so that the next submit can be ignored
        $form.data('submitted', true);
        // In the event there are multiple forms append loading spinner to applicable one.
        var dsp_button = $form.find(".dsp-protected-btn").not(":hidden")
        var dsp_button_append_class = $(".dsp-protected-btn").attr("data-append-class")
        dsp_button_append_class = dsp_button_append_class ?  dsp_button_append_class : ""
        // Can't disable the button or else the submitted value does not continue through.
        //dsp_button.prop("disabled", true)
        dsp_button.after(`<i class="fas fa-spinner fa-spin mx-2 ${dsp_button_append_class}"></i>`)
      }
    });

    // Keep chainability
    return this;
  };

  $(".timepicker").each((index, elem) => {
    createTimePickerItem(elem)
  })
  $(".datepicker").each((index, elem) => {
    createDatePickerItem(elem)
  })
  $(".datetimepicker").each((index, elem) => {
    createDateTimePickerItem(elem)
  })


  $('form.dsp-protected').preventDoubleSubmission();
  import('./modules/accountInviteForm')
  import('./modules/browserUpdate')
  import('./modules/charts')
  import('./modules/companyLocation')
  import('./modules/conductTraining')
  import('./modules/customFileInput')
  import('./modules/dateRangeFilter')
  import('./modules/employeeRecord')
  import('./modules/employeeUpload')
  import('./modules/openAssignments')
  import('./modules/quickRecord')
  import('./modules/quickSchedule')
  import('./modules/roleDetail')
  import('./modules/saveReport')
  import('./modules/savedReportList')
  import('./modules/savedReportTileBadge')
  import('./modules/sendPolicies')
  import ('./modules/bulkEditEmployees')
  import('./modules/signaturePad')
  import('./modules/trainingRecordEditable')
  import('./modules/trainingSessionApprovals')
  import('./modules/trainingSessionChangeDate')
  import('./modules/trainingSessionDetail')
  import('./modules/trainingTopicDetail')
  import('./modules/uploadDocument')
  import('./modules/trainingTestFormset')
  import('./modules/trainingTestAttempt')
  import ('./modules/sendMCTestEmail')
  import('./modules/notificationRecipientFormset')
  import('./modules/customVideoUpload')
  import('./modules/effectiveStatementDetails')
  import('./modules/stock_recovery/affectedDC')
  import('./modules/stock_recovery/affectedProductSummary/afpsForms')
  import('./modules/stock_recovery/affectedProductRecovery/afpr')
  import('./modules/stock_recovery/effectiveStatementList')
  import('./modules/stock_recovery/finalCostSummary/associatedCost')
  import('./modules/stock_recovery/finalCostSummary/equipmentCost')
  import('./modules/stock_recovery/finalCostSummary/driverCost')
  import('./modules/stock_recovery/finalCostSummary/warehouseLaborCost')
  import ('./modules/stock_recovery/finalCostSummary/adminCost')
  import ('./modules/stock_recovery/finalCostSummary/productCost')
  import ('./modules/stock_recovery/finalCostSummary/restaurantCredit')
  import('./modules/stock_recovery/finalCostSummary/finalDisposition')
  import('./modules/stock_recovery/finalCostSummary/totalCost')
})
