import { dqsa, dqs, fetchModelsJSON, locationsJSON } from './utils'

import { toaster } from './toasts'

const $conductTrainingForm = $('#conduct-training-form')

if ($conductTrainingForm.length) {
  const form = dqs('form')
  const checkboxSendNotifications = dqs('#id-send-notifications')
  const topicAndPolicyCheckboxes = dqsa('[id^=checkbox]')
  const copyInvitedButton = dqs('#id-btn-copy-invited')
  const submitButton = dqs('#id-submit-button')
  const folderIcons = dqsa('.fa-folder-open')
  const invitedField = $('#id_invited')
  const participantsField = $('#id_participants')
  const locationField = $('#id_location')

  const toggleNotifyEmployeesCheckbox = shouldAllow => {
    if (shouldAllow) {
      checkboxSendNotifications.removeAttribute('disabled')
    } else {
      checkboxSendNotifications.setAttribute('disabled', true)
      checkboxSendNotifications.checked = false
      dqs('#hint-id-send-notifications').innerText =
        'Disabled because this Location has Employee Notifications off.'
    }
  }

  const checkNotifyEmployees = e => {
    const locationID = locationField.select2('data')[0].id
    const loc = locationsJSON[locationID]
    if (loc !== undefined) {
      const shouldNotifyEmployees = loc.allow_session_notifications
      toggleNotifyEmployeesCheckbox(shouldNotifyEmployees)
    }
  }

  const submitForm = () => {
    const input = document.createElement('input')
    input.setAttribute('name', checkboxSendNotifications.name)
    input.setAttribute('id', checkboxSendNotifications.id)
    input.setAttribute('type', 'hidden')
    checkboxSendNotifications.checked && input.setAttribute('value', 'on')
    form.appendChild(input)
    form.submit()
  }

  const handleSubmit = event => {
    event.preventDefault()
    // Check that every training topic and policy is checked off
    if (Array.from(topicAndPolicyCheckboxes).every(checkbox => checkbox.checked)) {
      submitForm()
    } else {
      toaster.makeToast({
        howToasty: toaster.DANGER,
        message: 'All topics and policies must be checked off.'
      })
    }
  }

  const copyAllInvited = () => {
    const invited = invitedField.select2('data')
    const participantIds = participantsField
      .select2('data')
      .reduce((arrOfIds, employee) => {
        arrOfIds.push(employee.id)
        return arrOfIds
      }, [])
    invited.forEach(invitee => {
      // Skip adding employees already in the participants field
      if (participantIds.includes(invitee.id)) {
        return
      }

      // Create a new JS Option object and add it to the participants field
      const option = new Option(invitee.text, invitee.id, true, true)
      participantsField.append(option).trigger('change')
    })
  }

  // Toggle show/hide icon on file list for each training topic
  folderIcons.forEach(icon =>
    icon.addEventListener('click', e => {
      e.target.classList.toggle('fa-folder-minus')
      e.target.classList.toggle('fa-folder-open')
    })
  )

  fetchModelsJSON().then(irrelevant => checkNotifyEmployees())
  copyInvitedButton.addEventListener('click', copyAllInvited)
  submitButton.addEventListener('click', handleSubmit)
  locationField.on('change', checkNotifyEmployees)
}
