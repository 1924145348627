import { captureError } from './utils'

// Employee record page, open collapsible panels if they were sorted or paginated
const params = new URL(document.location).searchParams
const cttsort = params.get('cttsort')
const cttpage = params.get('cttpage')
if (cttsort || cttpage) {
  $('#historical-records').collapse('show')
}

const polsort = params.get('polsort')
const polpage = params.get('polpage')
if (polsort || polpage) {
  $('#policies-signed').collapse('show')
}

const certsort = params.get('certsort')
const certpage = params.get('certpage')
if (certsort || certpage) {
  $('#trainer-qualifications').collapse('show')
}

// Employee note form
const $employeeNoteForm = $('#employee-note-form')

if ($employeeNoteForm.length) {
  const handleSubmit = e => {
    e.preventDefault()

    const url = $employeeNoteForm.attr('action')
    const formData = $employeeNoteForm.serialize()

    $.post(url, formData)
      .done(data => {
        const { timestamp, author, body } = data
        const row = `
        <tr>
            <td class="no-wrap">${timestamp}</td>
            <td>${author}</td>
            <td>${body}</td>
        </tr>`
        $('#employee-notes-table tbody').prepend(row)

        const count = parseInt($('#badge-count-employee-notes').html())

        $('#badge-count-employee-notes').text(count + 1)
      })
      .fail(err => {
        captureError(err)
      })

    $employeeNoteForm.trigger('reset')
  }
  $employeeNoteForm.on('submit', handleSubmit)
}
