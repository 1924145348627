import { captureError, dqs } from './utils'
import { toaster } from './toasts'

const form = dqs('#employee-upload-spreadsheet-form')

if (form !== null) {
  const $progressModal = $('#uploadProgressModal')
  const progressBar = dqs('#upload-progress-bar')
  form.addEventListener('submit', e => {
    e.preventDefault()

    let taskID = null
    let intervalID = null

    const checkProgress = () => {
      fetch(`${window.location.href}${taskID}/`)
        .then(response => {
          if (response.status === 200) {
            return response.json()
          }
          throw new Error(response)
        })
        .then(data => {
          if (data.current !== undefined) {
            progressBar.style.width = `${(data.current / data.total) * 100}%`
            progressBar.innerText = `${data.current}/${data.total}`
          } else if (data === 'PENDING') {
          } else if (data === 'SUCCESS') {
            clearTimeout(intervalID)
            window.location.assign(`${window.location.href}${taskID}/results/`)
          } else {
            throw new Error('celery task status not in the expected range')
          }
        })
        .catch(e => {
          clearInterval(intervalID)
          $progressModal.modal('hide')
          captureError(e)
        })
    }

    fetch(window.location.href, {
      method: form.getAttribute('method'),
      body: new FormData(e.target)
    })
      .then(response => response.json())
      .then(data => {
        if (data.taskID !== undefined) {
          $progressModal.modal('show')
          taskID = data.taskID
          intervalID = setInterval(checkProgress, 333)
        } else if (data.error !== undefined) {
          toaster.makeToast({
            howToasty: toaster.DANGER,
            message: data.error
          })
        }
      })
      .catch(e => captureError(e))
  })
}
