import {showElement, hideElement, getCookie, parseTimeInput} from "../../utils";

let afps_form1 = $("#affectedProductSummaryForm1")
let afps_form2 = $("#affectedProductSummaryForm2")
let afps_form2_dc_hub = $("#dc-hub-cases-form")
let afps_form3 = $("#affectedProductSummaryForm3")


if (afps_form1.length) {
    let received_selector = "input[name='did_receive_cases_from_dc']"
    let received_cases_div = $("#div_cases_received")
    let distributed_selector = "input[name='did_distribute_cases_to_dc']"
    let distributed_cases_div = $("#div_cases_distributed")
    let cases_distributed_input = $("input[name='distributed_case_count']")
    let on_hold_input = $("input[name='cases_on_hold']")
    let in_use_input = $("input[name='cases_in_use_or_dmg']")
    let total_cases_account_for_input = $("input[name='cases_accounted_for']")
    let dcs_distributed_to_div = $("#dcs_distributed_to_div")

    function updateTotalAccountedFor() {
        let distributed_count = parseInt(cases_distributed_input.val()) || 0
        let on_hold_count = parseInt(on_hold_input.val()) || 0
        let in_use_count = parseInt(in_use_input.val()) || 0

        let count_affected = distributed_count + on_hold_count + in_use_count
        total_cases_account_for_input.val(count_affected)
    }

    cases_distributed_input.on("change", updateTotalAccountedFor)
    on_hold_input.on("change", updateTotalAccountedFor)
    in_use_input.on("change", updateTotalAccountedFor)


    $(received_selector).on("change", (event) => {
        if(event.target.value === "True") {
            showElement(received_cases_div[0])
        } else {
            hideElement(received_cases_div[0])
        }
    })

    $(distributed_selector).on("change", (event) => {
        if(event.target.value === "True") {
            if(dcs_distributed_to_div.length > 0) {
                showElement(dcs_distributed_to_div[0])
            } else {
                showElement(distributed_cases_div[0])
            }


        } else {
            if(dcs_distributed_to_div.length > 0 ) {
                hideElement(dcs_distributed_to_div[0])
            } else {
                hideElement(distributed_cases_div[0])
            }
        }
    })
}

if (afps_form2.length || afps_form2_dc_hub.length) {
    let affected_count_input = $("#id_num_restaurants_affected")
    let contacted_count_input = $("#id_num_restaurants_contacted")
    let percentage_contacted = $("#id_percentage_contacted")

    const effectiveStatementID = $('#id_effective_statement_id')
    const distributionCenterID = $('#id_distribution_center_id')
    const dateContacted = $("#id_date_contacted")
    const timeContacted = $("#id_time_contacted")
    const timeTakenToNotify = $("#id_time_dc_took")

    let successFunction = (data) => {
        let result = data["time_diff"]
            if(result) {
                timeTakenToNotify.val(result)
            }
        }
    let errorFunction = (xhr) => {
        console.error(xhr)
    }

    function fetch_time_dc_took_to_notify() {
        let date_val = dateContacted.val()
        let time_val = timeContacted.val()
        // If we don't have one of the values, we can't combine them to create our moment.js object to pass as data
        if(!date_val || !time_val) {
            return
        }
        // Grab the values of our date/timepickers
        let dummy_date = new Date(date_val)

        // Grab the time, split it into hours, minutes, ampm
        let {hours, minutes} = parseTimeInput(timeContacted)
        // Combine the time with the date
        dummy_date.setHours(hours)
        dummy_date.setMinutes(minutes)
        let date_obj = new Date(Date.UTC(dummy_date.getFullYear(), dummy_date.getMonth(), dummy_date.getDate(), dummy_date.getHours(), dummy_date.getMinutes()))
        let data = new FormData()


        let csrftoken = getCookie("smgcsrftoken")
        // This toISOString will now be the actual date/time the User put in. It will not be the actual UTC time
        // This simplifies the timezone conversions on the backend
        data.set("dt", date_obj.toISOString())
        data.set("csrfmiddlewaretoken", csrftoken)
        let action_url = `/stock/api/v1/dc/${distributionCenterID.val()}/effective_statement/${effectiveStatementID.val()}/calculate/duration/`
        $.ajax({
            type: "POST",
            url: action_url,
            data: data,
            processData: false,
            contentType: false,
            success: successFunction,
            error: errorFunction,
        })
    }

    // The handler checks for both date and time value before making an ajax request
    dateContacted.on("change.datetimepicker", (old_date, new_date) => {
        fetch_time_dc_took_to_notify()
    })
    timeContacted.on("change.datetimepicker", (old_date, new_date) => {
        fetch_time_dc_took_to_notify()
    })

    function update_percentage_handler (event) {

        let total = parseInt(affected_count_input.val())
        let contacted = parseInt(contacted_count_input.val())
        if(isNaN(total) || isNaN(contacted) || !total > 0) {
            //default to 0%
            percentage_contacted.val("0%")
            return
        }
        let percentage = contacted/total * 100
        let percentage_string = `${Math.round(percentage)}%`
        percentage_contacted.val(percentage_string)
    }
    // set initial data on page load
    update_percentage_handler()
    affected_count_input.on("input", update_percentage_handler)
    contacted_count_input.on("input", update_percentage_handler)
}

if(afps_form3.length) {
    const effectiveStatementID = $('#id_effective_statement_id')
    const distributionCenterID = $('#id_distribution_center_id')
    const dateOfAccountability = $("#id_date_of_accountability")
    const timeOfAccountability = $("#id_time_of_accountability")
    const productAccountabilityDuration = $("#id_product_accountability_duration")
    const casesInRestaurant = $("#id_num_cases_in_restaurant")
    const casesDistributed = $("#id_distributed_case_count")
    const percentageUnrecoverable = $("#id_percentage_unrecoverable")
    const casesNotInInventory = $("#id_cases_not_in_inventory")

    let successFunction = (data) => {
        let result = data["time_diff"]
            if(result) {
                productAccountabilityDuration.val(result)
            }
        }
    let errorFunction = (xhr) => {
        console.error(xhr)
    }

    function fetch_product_accountability_duration() {
        let date_val = dateOfAccountability.val()
        let time_val = timeOfAccountability.val()
        // If we don't have one of the values, we can't combine them to create our moment.js object to pass as data
        if(!date_val || !time_val) {
            return
        }
        // Grab the values of our date/timepickers
        let dummy_date = new Date(date_val)
        // Grab the time, split it into hours, minutes, ampm
        let {hours, minutes} = parseTimeInput(timeOfAccountability)
        // Combine the time with the date
        dummy_date.setHours(hours)
        dummy_date.setMinutes(minutes)
        let date_obj = new Date(Date.UTC(dummy_date.getFullYear(), dummy_date.getMonth(), dummy_date.getDate(), dummy_date.getHours(), dummy_date.getMinutes()))
        let data = new FormData()
        // Combine the time with the date
        let csrftoken = getCookie("smgcsrftoken")
        // This toISOString will now be the actual date/time the User put in. It will not be the actual UTC time
        // This simplifies the timezone conversions on the backend
        data.set("dt", date_obj.toISOString())
        data.set("csrfmiddlewaretoken", csrftoken)

        let action_url = `/stock/api/v1/dc/${distributionCenterID.val()}/effective_statement/${effectiveStatementID.val()}/calculate/duration/`
        $.ajax({
            type: "POST",
            url: action_url,
            data: data,
            processData: false,
            contentType: false,
            success: successFunction,
            error: errorFunction,
        })
    }

    // The handler checks for both date and time value before making an ajax request
    dateOfAccountability.on("change.datetimepicker", (old_date, new_date) => {
        fetch_product_accountability_duration()
    })
    timeOfAccountability.on("change.datetimepicker", (old_date, new_date) => {
        fetch_product_accountability_duration()
    })

    function update_unrecoverable_fields (event) {

        let total = parseInt(casesDistributed.val())
        let cases_recovered = parseInt(casesInRestaurant.val())

        if(isNaN(total) || isNaN(cases_recovered) || !total > 0) {
            //default to 0%
            percentageUnrecoverable.val("0%")
            return
        }

        let cases_unrecovered = total - cases_recovered
        if(cases_unrecovered < 0) {
            cases_unrecovered = 0
        }

        let percentage = cases_unrecovered/total * 100
        let percentage_string = `${Math.round(percentage)}%`
        casesNotInInventory.val(cases_unrecovered)
        percentageUnrecoverable.val(percentage_string)
    }
    casesInRestaurant.on("change", update_unrecoverable_fields)
}