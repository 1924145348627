// Browser update config
$buo({
  required: { e: -2, f: -2, o: -1, s: -1, c: -2 },
  insecure: true,
  unsupported: true,
  reminder: 0,
  noclose: true,
  no_permanent_hide: true,
  text: {
    msgmore:
      'Use the latest version of Google Chrome, Mozilla Firefox, Microsoft Edge, or Safari.',
    bupdate: 'Update'
  },
  api: 2020.03
})
