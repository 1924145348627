import { dqs, captureError } from './utils'

const $roleForm = $('#role-form')

if ($roleForm.length) {
  const ASSIGNMENT_TYPE_TOPIC = 'att'
  const ASSIGNMENT_TYPE_POLICY = 'atp'

  // Globally track item currently being dragged
  let currentlyDraggingItem = null

  // Only allow dragging training topics onto training topics
  // and policies onto policies
  const validateDraggableType = e => currentlyDraggingItem === e.currentTarget.dataset.smgType

  const dragStartHandler = e => {
    e.originalEvent.dataTransfer.effectAllowed = 'move'
    e.originalEvent.dataTransfer.setData('text/plain', e.currentTarget.id)
    currentlyDraggingItem = e.currentTarget.dataset.smgType
  }

  const dragEnterHandler = e => {
    if (validateDraggableType(e)) {
      e.preventDefault()
      e.originalEvent.dataTransfer.dropEffect = 'move'
      e.currentTarget.classList.add('valid-drop-target')
    }
  }

  const dragOverHandler = e => {
    if (validateDraggableType(e)) {
      e.preventDefault()
      e.originalEvent.dataTransfer.dropEffect = 'move'
    }
  }

  const dragLeaveHandler = e => {
    if (validateDraggableType(e)) {
      e.currentTarget.classList.remove('valid-drop-target')
    }
  }

  const dropHandler = e => {
    if (validateDraggableType(e)) {
      e.preventDefault()
      const data = e.originalEvent.dataTransfer.getData('text/plain')
      $(`#${data}`).insertBefore(e.currentTarget)
      e.currentTarget.classList.remove('valid-drop-target')
    }
  }

  const createListGroupItem = (assignmentType, id, text) => {
    const element = document.createElement('li')
    element.classList.add('list-group-item', 'draggable', 'collapse', 'fade-in-slow')
    element.setAttribute('id', `list-group-item-${assignmentType}-${id}`)
    element.setAttribute('draggable', 'true')
    element.setAttribute('data-smg-type', assignmentType)
    element.setAttribute('data-smg-id', id)
    element.innerHTML =
      '<i class="fas fa-grip-vertical"></i>' +
      text +
      `<i class="fas fa-trash-alt" data-smg-delete-type="${assignmentType}" data-smg-delete-id="${id}"></i>`
    return element
  }

  const deleteHandler = e => {
    const deleteTargetType = $(e.currentTarget).attr('data-smg-delete-type')
    const deleteTargetID = $(e.currentTarget).attr('data-smg-delete-id')
    $(`[data-smg-type='${deleteTargetType}'][data-smg-id='${deleteTargetID}']`).remove()
  }

  // Native JS selectors
  const form = dqs('#role-form')
  const selectedTrainingsListGroup = dqs('#training-topic-list')
  const selectedPoliciesListGroup = dqs('#policy-list')

  // Setup events for draggables
  $(document).on('dragstart', '.draggable', dragStartHandler)
  $(document).on('dragenter', '.draggable', dragEnterHandler)
  $(document).on('dragover', '.draggable', dragOverHandler)
  $(document).on('dragleave', '.draggable', dragLeaveHandler)
  $(document).on('drop', '.draggable', dropHandler)
  $(document).on('click', '[data-smg-delete-id]', deleteHandler)

  // jQuery selectors
  // Required for working with Select2 methods
  const trainingTopic = $('#id_training_topic')
  const policy = $('#id_policy')

  const addAssignment = ({ assignmentType }) => {
    let addedId = null
    let addedName = null
    let listItem = null
    if (assignmentType === ASSIGNMENT_TYPE_TOPIC) {
      addedId = trainingTopic.select2('data')[0].id
    } else if (assignmentType === ASSIGNMENT_TYPE_POLICY) {
      addedId = policy.select2('data')[0].id
    } else {
      captureError(new Error('Something went wrong.'))
    }

    const assignmentId = `#list-group-item-${assignmentType}-${addedId}`

    if ($(assignmentId).length === 0) {
      if (assignmentType === ASSIGNMENT_TYPE_TOPIC) {
        addedName = trainingTopic.select2('data')[0].text
        listItem = createListGroupItem(assignmentType, addedId, addedName)
        selectedTrainingsListGroup.appendChild(listItem)
      } else if (assignmentType === ASSIGNMENT_TYPE_POLICY) {
        addedName = policy.select2('data')[0].text
        listItem = createListGroupItem(assignmentType, addedId, addedName)
        selectedPoliciesListGroup.appendChild(listItem)
      } else {
        captureError(new Error('Something went wrong.'))
      }
      $(listItem).collapse('show')
    }
    $(trainingTopic).val(null).trigger('change')
    $(policy).val(null).trigger('change')
  }

  const buildAssignmentIDsFromHTML = htmlCollection => {
    const ids = []
    for (let element of htmlCollection.children) {
      ids.push(element.dataset.smgId)
    }
    return ids
  }

  const handleSubmit = e => {
    const trainingsTopics = buildAssignmentIDsFromHTML(selectedTrainingsListGroup)
    const policies = buildAssignmentIDsFromHTML(selectedPoliciesListGroup)

    $(form).append(
      `<input type="hidden" name="training_topics" value="${trainingsTopics}"/>`
    )
    $(form).append(`<input type="hidden" name="policies" value="${policies}"/>`)

    return true
  }

  trainingTopic.on('select2:select', () =>
    addAssignment({
      assignmentType: ASSIGNMENT_TYPE_TOPIC
    })
  )
  policy.on('select2:select', () =>
    addAssignment({
      assignmentType: ASSIGNMENT_TYPE_POLICY
    })
  )
  form.onsubmit = handleSubmit
}
