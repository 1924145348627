import { activateSelectAllCheckbox } from './utils'

const sendPoliciesForm = $('#send-policies-form')

if (sendPoliciesForm.length) {
  // const submitButton = dqs('#send-policies-submit-btn')
  const selectAllPoliciesCheckbox = $('#select-all-policies-checkbox')
  const policyCheckboxes = $('input[type="checkbox"][name="p"]:not([disabled])')

  if (selectAllPoliciesCheckbox.length && policyCheckboxes.length) {
    activateSelectAllCheckbox(selectAllPoliciesCheckbox, policyCheckboxes)
  }

  const selectAllEmployeesCheckbox = $('#select-all-employees-checkbox')
  const employeeCheckboxes = $('input[type="checkbox"][name="e"]:not([disabled])')

  if (selectAllEmployeesCheckbox.length && employeeCheckboxes.length) {
    activateSelectAllCheckbox(selectAllEmployeesCheckbox, employeeCheckboxes)
  }
}
