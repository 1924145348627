import { activateSelectAllCheckbox, countCheckboxes, dqsa, dqs, postForm } from './utils'

import { toaster } from './toasts'

if ($('#training-session-approvals-card').length) {
  const $selectAllTrainingTopicsCheckbox = $('#select-all-training-topics-checkbox')
  const $selectAllPoliciesCheckbox = $('#select-all-policies-checkbox')

  const submitButton = dqs('#training-session-approvals-submit-button')
  const submitButtonCount = dqs('#submit-btn-count')
  const $topicCheckboxes = $(
    'input[type="checkbox"][id^="training-topic-"]:not([disabled])'
  )
  const $policyCheckboxes = $('input[type="checkbox"][id^="policy-"]:not([disabled])')
  let countChecked = 0

  const countCheckedRecords = () => {
    const countCheckedTopics = countCheckboxes($topicCheckboxes)
    const countCheckedPolicies = countCheckboxes($policyCheckboxes)
    countChecked = countCheckedTopics + countCheckedPolicies
    if (countChecked == 0) {
      submitButton.setAttribute('disabled', '')
    } else {
      submitButton.removeAttribute('disabled')
    }
    submitButtonCount.innerText = ` ${countChecked} Record${countChecked != 1 ? 's' : ''}`
  }

  if ($selectAllTrainingTopicsCheckbox.length) {
    activateSelectAllCheckbox($selectAllTrainingTopicsCheckbox, $topicCheckboxes)
    $selectAllTrainingTopicsCheckbox.on('change', countCheckedRecords)
  }
  if ($selectAllPoliciesCheckbox.length) {
    activateSelectAllCheckbox($selectAllPoliciesCheckbox, $policyCheckboxes)
    $selectAllPoliciesCheckbox.on('change', countCheckedRecords)
  }

  countCheckedRecords()

  $topicCheckboxes.each((idx, checkbox) =>
    checkbox.addEventListener('change', countCheckedRecords)
  )
  $policyCheckboxes.each((idx, checkbox) =>
    checkbox.addEventListener('change', countCheckedRecords)
  )

  const onError = event => {
    toaster.makeToast({
      howToasty: toaster.DANGER,
      message: 'Error communicating with the server. Please try again later.'
    })
  }

  const onLoad = event => {
    const client = event.target
    if (client.status == 200 && client.responseURL != '') {
      const suffix = countChecked > 1 ? 's' : ''
      sessionStorage.setItem('smgToast', `${countChecked} record${suffix} approved.`)
      window.location.assign(event.target.responseURL)
    } else {
      toaster.makeToast({
        howToasty: toaster.DANGER,
        message: 'Error while approving records. Please try again later.'
      })
    }
  }

  const handleSubmit = event => {
    event.preventDefault()
    if (countChecked) {
      const tRecordIds = []
      const policyRecordIds = []
      const checkedTopicRecordCheckboxes = dqsa('input[id^="training-topic-"]:checked')
      const checkedPolicyRecordCheckboxes = dqsa('input[id^="policy-"]:checked')
      checkedTopicRecordCheckboxes.forEach(checkbox =>
        tRecordIds.push(checkbox.dataset.smgId)
      )
      checkedPolicyRecordCheckboxes.forEach(checkbox =>
        policyRecordIds.push(checkbox.dataset.smgId)
      )

      const formData = new FormData(event.target)
      formData.append('topic_record_ids', tRecordIds)
      formData.append('policy_record_ids', policyRecordIds)
      postForm(event.target.action, formData, onError, onLoad)
    } else {
      toaster.makeToast({
        howToasty: toaster.WARNING,
        message: 'Please select at least one record to approve.'
      })
    }
  }

  $('#training-session-approvals-form').on('submit', handleSubmit)
}
