import {simpleRateMultiplier} from "../../utils";

let final_cost_form = $("#final-cost-summary-form")

if (final_cost_form.length) {
    let truck_rental_days = $("#id_straight_truck_rental_days")
    let truck_rental_rate = $("#id_straight_truck_rental_rate")
    let truck_rental_total = $("#id_truck_rental_total")
    let truck_hours = $("#id_straight_truck_hours")
    let truck_hourly_rate = $("#id_straight_truck_hourly_rate")
    let truck_hourly_total = $("#id_truck_hourly_total")
    let truck_milage = $("#id_straight_truck_miles")
    let truck_milage_rate = $("#id_straight_truck_milage_rate")
    let truck_milage_total = $("#id_truck_milage_total")

    let trailer_rental_days = $("#id_trailer_rental_days")
    let trailer_rental_rate = $("#id_trailer_rental_rate")
    let trailer_rental_total = $("#id_trailer_rental_total")
    let trailer_hours = $("#id_trailer_hours")
    let trailer_hourly_rate = $("#id_trailer_hourly_rate")
    let trailer_hourly_total = $("#id_trailer_hourly_total")
    let trailer_miles = $("#id_trailer_miles")
    let trailer_milage_rate = $("#id_trailer_milage_rate")
    let trailer_milage_total = $("#id_trailer_milage_total")

    let tractor_rental_days = $("#id_tractor_rental_days")
    let tractor_rental_rate = $("#id_tractor_rental_rate")
    let tractor_rental_total = $("#id_tractor_rental_total")
    let tractor_hours = $("#id_tractor_hours")
    let tractor_hourly_rate = $("#id_tractor_hourly_rate")
    let tractor_hourly_total = $("#id_tractor_hourly_total")
    let tractor_miles = $("#id_tractor_miles")
    let tractor_milage_rate = $("#id_tractor_milage_rate")
    let tractor_milage_total = $("#id_tractor_milage_total")

    let fuel_used = $("#id_fuel_used")
    let cost_per_gallon = $("#id_cost_per_gallon")
    let fuel_total = $("#id_fuel_total")
    let total_equipment_cost = $("#id_total_equipment_cost")
    let equipment_title_cost = $("#equipment-title-cost")


    function handle_truck_rental_cost(event) {
        let truck_rental_total_val = simpleRateMultiplier(truck_rental_days, truck_rental_rate)
        truck_rental_total.val(truck_rental_total_val.toFixed(2))
        return truck_rental_total_val
    }

    function handle_truck_hours(event) {
        let truck_hourly_total_val = simpleRateMultiplier(truck_hours, truck_hourly_rate)
        truck_hourly_total.val(truck_hourly_total_val.toFixed(2))
        return truck_hourly_total_val
    }

    function handle_truck_milage(event) {
        let truck_milage_total_val = simpleRateMultiplier(truck_milage, truck_milage_rate)
        truck_milage_total.val(truck_milage_total_val.toFixed(2))
        return truck_milage_total_val
    }

    function handle_trailer_rental_cost(event) {
        let trailer_rental_total_val = simpleRateMultiplier(trailer_rental_days, trailer_rental_rate)
        trailer_rental_total.val(trailer_rental_total_val.toFixed(2))
        return trailer_rental_total_val
    }

    function handle_trailer_hours(event) {
        let trailer_hourly_total_val = simpleRateMultiplier(trailer_hours, trailer_hourly_rate)
        trailer_hourly_total.val(trailer_hourly_total_val.toFixed(2))
        return trailer_hourly_total_val
    }

    function handle_trailer_milage(event) {
        let trailer_milage_total_val = simpleRateMultiplier(trailer_miles, trailer_milage_rate)
        trailer_milage_total.val(trailer_milage_total_val.toFixed(2))
        return trailer_milage_total_val
    }

    function handle_tractor_rental_cost(event) {
        let tractor_rental_cost_val = simpleRateMultiplier(tractor_rental_days, tractor_rental_rate)
        tractor_rental_total.val(tractor_rental_cost_val.toFixed(2))
        return tractor_rental_cost_val
    }
    function handle_tractor_hours(event) {
        let total_val = simpleRateMultiplier(tractor_hours, tractor_hourly_rate)
        tractor_hourly_total.val(total_val.toFixed(2))
        return total_val
    }
    function handle_tractor_milage(event) {
        let total_val = simpleRateMultiplier(tractor_miles, tractor_milage_rate)
        tractor_milage_total.val(total_val.toFixed(2))
        return total_val
    }

    function handle_fuel_cost(event) {
        let total_val = simpleRateMultiplier(fuel_used, cost_per_gallon)
        fuel_total.val(total_val.toFixed(2))
        return total_val
    }


    function handle_total_equipment_cost(event) {
        let total_truck_cost = handle_truck_rental_cost(null)
        let total_truck_hourly = handle_truck_hours(null)
        let total_truck_milage = handle_truck_milage(null)
        let total_truck_cost_aggr = total_truck_cost + total_truck_hourly + total_truck_milage

        let total_trailer_cost = handle_trailer_rental_cost(null)
        let total_trailer_hourly = handle_trailer_hours(null)
        let total_trailer_milage = handle_trailer_milage(null)
        let total_trailer_aggr = total_trailer_cost + total_trailer_hourly + total_trailer_milage

        let total_tractor_cost = handle_tractor_rental_cost(null)
        let total_tractor_hourly = handle_tractor_hours(null)
        let total_tractor_milage = handle_tractor_milage(null)
        let total_tractor_aggr = total_tractor_cost + total_tractor_hourly + total_tractor_milage

        let total_fuel_cost = handle_fuel_cost(null)

        let total_equipment_cost_val = total_truck_cost_aggr + total_trailer_aggr + total_tractor_aggr + total_fuel_cost
        // Trigger Change event for totalCost.js
        total_equipment_cost.val(total_equipment_cost_val.toFixed(2)).trigger("change")
        equipment_title_cost.text(`Total Cost: $ ${total_equipment_cost_val.toFixed(2)}`)
    }

    // Initialize All Total values on load
    handle_total_equipment_cost()

    let all_equipment_jq_elements = [
        truck_rental_days,
        truck_rental_rate,
        truck_hours,
        truck_hourly_rate,
        truck_milage,
        truck_milage_rate,
        trailer_rental_days,
        trailer_rental_rate,
        trailer_hours,
        trailer_hourly_rate,
        trailer_miles,
        trailer_milage_rate,
        tractor_rental_days,
        tractor_rental_rate,
        tractor_hours,
        tractor_hourly_rate,
        tractor_miles,
        tractor_milage_rate,
        fuel_used,
        cost_per_gallon,
    ]
    // Attach the total equipment cost handler to all necessary elements
    // Since total equipment cost calls the individual handlers, we don't need to attach them separately.
    all_equipment_jq_elements.forEach((elem) => {
        elem.on("change", handle_total_equipment_cost)
    })

}

