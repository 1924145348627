import { dqs, hideElement, showElement } from './utils'

// Used on account invite form and edit user form
const accessLevelDropdown = dqs('#id_role')
const isTrainingTrackerCheckbox = dqs("#id_is_training_tracker")
const isRTProCheckbox = dqs("#id_is_rt_pro")
const observationAccessCheckbox = dqs("#id_observation_access")
const incidentAccessCheckbox = dqs("#id_incident_access")
const accountInviteForm = dqs(".account-invite-form")
const companyDropdown = dqs("#id_company")
const accountProfileFormSMG = dqs(".account-profile-form-smg")

const toggleRTProOptions = () => {
    const observationAccessDiv = dqs("#div_id_observation_access")
    const incidentAccessDiv = dqs("#div_id_incident_access")
    const accidentAccessDiv = dqs("#div_id_accident_access")
    const inspectionAccessDiv = dqs("#div_id_inspection_access")
    if (isRTProCheckbox.checked) {
      showElement(observationAccessDiv)
      showElement(incidentAccessDiv)
      showElement(accidentAccessDiv)
      showElement(inspectionAccessDiv)
    }
    else {
      hideElement(observationAccessDiv)
      hideElement(incidentAccessDiv)
      hideElement(accidentAccessDiv)
      hideElement(inspectionAccessDiv)
    }
  }

if(accountProfileFormSMG != null) {
  toggleRTProOptions()
  isRTProCheckbox.addEventListener("change", toggleRTProOptions)
}

if ( accountInviteForm != null) {
  const toggleTrainingTrackerDropdowns = () => {
    const accessLevelDiv = dqs("#div_id_role")
    const companyDiv = dqs("#div_id_company")
    const locationDiv = dqs('#div_id_location')

    if(isTrainingTrackerCheckbox.checked) {
      $("#id_company").attr("required", true)
      $("#id_role").attr("required", true)
      showElement(companyDiv)
      showElement(accessLevelDiv)
      // Dispatch the change event for the access level to determine if we show the location selector
      // Allows us to retain the previously selected access level option if it was hidden
      accessLevelDropdown.dispatchEvent(new Event("change"))
    }
    else {
      $("#id_company").attr("required", null)
      $("#id_role").attr("required", null)
      hideElement(companyDiv)
      hideElement(accessLevelDiv)
      // Make sure we hide the location div in case it is shown.
      hideElement(locationDiv)
    }
  }

  if(companyDropdown !== null)
  {
    // Append asterisks to required fields
    // Do it through JS since they are only required if certain options are selected
    const companyLabel = dqs("label[for='id_company']")
    companyLabel.innerText = companyLabel.innerText.trim()
    companyLabel.insertAdjacentHTML('beforeEnd', '<span class="asteriskField">*</span>')
    const accessLevelLabel = dqs("label[for='id_role']")
    accessLevelLabel.innerText = accessLevelLabel.innerText.trim()
    accessLevelLabel.insertAdjacentHTML('beforeEnd', '<span class="asteriskField">*</span>')
    // Hide or show the appropriate fields based on what is checked
    // Run once on form load
    toggleTrainingTrackerDropdowns()
    toggleRTProOptions()
    isTrainingTrackerCheckbox.addEventListener("change", toggleTrainingTrackerDropdowns)
    isRTProCheckbox.addEventListener("change", toggleRTProOptions)
  }
}

if (accessLevelDropdown != null) {
  const locationLabel = dqs("label[for='id_location']")
  locationLabel.innerText = locationLabel.innerText.trim()
  locationLabel.insertAdjacentHTML('beforeEnd', '<span class="asteriskField">*</span>')
  const locationDiv = dqs('#div_id_location')
  const toggleLocationDropdown = () => {
    if (accessLevelDropdown.selectedOptions[0].text === 'Location Admin') {
      showElement(locationDiv)
      $('#id_location').attr('required', true)
    } else {
      hideElement(locationDiv)
      $('#id_location').val(null).trigger('change')
      $('#id_location').attr('required', null)
    }
  }

  toggleLocationDropdown()
  accessLevelDropdown.addEventListener('change', toggleLocationDropdown)
}



