const SUCCESS = 't-success'
const INFO = 't-info'
const WARNING = 't-warning'
const DANGER = 't-danger'

// Remove toasts from the DOM after they hide
$('.toasts-container').on('hidden.bs.toast', '.toast', e => {
  e.currentTarget.remove()
})

// Show toasts on load if there are any
if ($('.toast').length) {
  $('.toast').toast('show')
}

const makeToast = ({ howToasty, message }) => {
  let toastClass = null
  let toastIcon = null

  switch (howToasty) {
    case SUCCESS:
      toastClass = 'success-toast'
      toastIcon = '<i class="fas fa-clipboard-check fa-lg"></i>'
      break
    case INFO:
      toastClass = 'info-toast'
      toastIcon = '<i class="fas fa-sticky-note fa-lg"></i>'
      break
    case WARNING:
      toastClass = 'warning-toast'
      toastIcon = '<i class="fas fa-exclamation-circle fa-lg"></i>'
      break
    case DANGER:
      toastClass = 'danger-toast'
      toastIcon = '<i class="fas fa-times-hexagon fa-lg"></i>'
      break
    default:
      toastClass = ''
      toastIcon = ''
      break
  }
  $('.toasts-container').append(
    `<div class="toast ${toastClass}" role="alert" data-delay=4000 aria-live="assertive" aria-atomic="true">
            <div class="toast-header">
                ${toastIcon}<strong class="mr-auto">SMG</strong><button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </div>
            <div class="toast-body">${message}</div>
        </div>`
  )
  $('.toast').toast('show')
}

// Get any toasts stored in sessionStorage and display them
const storedToast = sessionStorage.getItem('smgToast')
if (storedToast != null) {
  sessionStorage.removeItem('smgToast')
  makeToast({
    howToasty: SUCCESS,
    message: storedToast
  })
}

export const toaster = {
  SUCCESS,
  INFO,
  WARNING,
  DANGER,
  makeToast
}
