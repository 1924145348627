import { toaster } from './toasts'
import {getCookie} from "./utils";

const video_upload_form = $("#video-upload-accident-form")
const video_input = $("#id_videos")

/**
 * Workflow for posting Videos
 * Make an AJAX call to retrieve a presigned_post URL for each file
 * On Success:
 * Make an ajax call to post the video to the presigned URL
 * On Success:
 * Make an ajax call to the video-upload-finished endpoint
 * On Success:
 * Reload RTPro, close window
 */

if (video_upload_form.length > 0) {
    function handle_video_upload_complete_success(data){
        let spinner = video_upload_form.find("i.fa-spin")
        let targetOrigin = "https://app.smgsafety.com"
        // Change to development origin for dev-site or localhost
        if (window.location.origin === "https://compliance.smgsafety-test.com" || window.location.origin === "https://compliance.smgsafety.net:8000") {
            targetOrigin = "https://app.smgsafety-test.com"
        }
        window.opener.postMessage('reload', targetOrigin)
        spinner.remove()
        window.close()
    }
    function handle_video_upload_complete_failure(data){
        toaster.makeToast({ howToasty: 't-danger', message: 'Failed to finish video upload. Please contact an SMG administrator. Error Code: SMG-V3' })
        // Unset the DSP Flag
        video_upload_form.data("submitted", "false")
        // Remove the upload spinner
        video_upload_form.find(".fa-spinner").remove()
    }

    function handle_video_upload_success(success_endpoint) {
        let formData = new FormData()
        let csrf_token = getCookie("smgcsrftoken")
        formData.append("csrfmiddlewaretoken", csrf_token)
        $.ajax({
            type: "POST",
            url: success_endpoint,
            data: formData,
            processData: false,
            contentType: false,
            success: handle_video_upload_complete_success,
            error: handle_video_upload_complete_failure,
        })
    }
    function handle_video_upload_failure(data) {
        toaster.makeToast({ howToasty: 't-danger', message: 'Failed to upload video. If this problem persists please contact an SMG administrator. Error Code: SMG-V2' })
        // Unset the DSP Flag
        video_upload_form.data("submitted", "false")
        // Remove the upload spinner
        video_upload_form.find(".fa-spinner").remove()
    }

    function handle_upload_start_success(data) {
        const presigned_post = data.presigned_post
        let formData = new FormData()
        let file = video_input.prop("files")[0]

        // Attach our presigned_post fields to the formData
        for (const key in presigned_post.fields) {
            formData.append(key, presigned_post.fields[key]);
        }
        // We don't need csrftoken to send to S3
        // let csrf_token = getCookie("smgcsrftoken")
        // formData.append("csrfmiddlewaretoken", csrf_token)
        // Attach our file object to the formData
        formData.append("file", file)
        // Post the Form Data
        $.ajax({
            type:"POST",
            url: presigned_post.url,
            data: formData,
            processData: false,
            contentType: false,
            success: () => handle_video_upload_success(data.finished_post),
            error: handle_video_upload_failure,
        })
    }
    function handle_upload_start_failure(data) {
        toaster.makeToast({ howToasty: 't-danger', message: 'Failed to upload video. If this problem persists please contact an SMG administrator. Error Code: SMG-V1' })
        // Unset the DSP Flag
        video_upload_form.data("submitted", "false")
        // Remove the upload spinner
        video_upload_form.find(".fa-spinner").remove()

    }
    function handle_video_submit(event) {
        event.preventDefault()
        let action_url = $(event.target).attr("action")
        let files = Array.from(video_input[0].files)
        let csrf_token = getCookie("smgcsrftoken")

        files.forEach((file) => {
            let formData = new FormData(video_upload_form[0])
            // Delete the video binary-data from the POST
            // This will be handled by uploading directly to S3
            formData.delete('videos')
            let file_info = {
                "filename": file.name,
                "size": file.size,
                "type": file.type
            }
            formData.append("file_info", JSON.stringify(file_info))
            formData.append("csrfmiddlewaretoken", csrf_token)
            $.ajax({
            type: "POST",
            url: action_url,
            data: formData,
            processData: false,
            contentType: false,
            success: handle_upload_start_success,
            error: handle_upload_start_failure,
            })
        })
    }
    video_upload_form.on("submit", handle_video_submit)
}