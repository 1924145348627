import {valueOrZero} from "../../utils";

let final_cost_form = $("#final-cost-summary-form")

if (final_cost_form.length) {
    let total_cost_products = $(".product-js-total")
    let total_credit_products = $(".credit-js-total")
    let total_associated_cost = $("#id_total_associated_cost")
    let total_equipment_cost = $("#id_total_equipment_cost")
    let total_driver_cost = $("#id_total_driver_cost")
    let total_warehouse_cost = $("#id_total_warehouse_cost")
    let total_admin_cost = $("#id_total_admin_cost")
    let total_expense_cost = $("#id_total_expenses_cost")
    let total_product_credit = $("#id_total_product_credits")
    let total_recovery_cost = $("#id_total_cost")
    let total_title_cost = $("#total_title_cost")

    let elems_affecting_total = [
        total_cost_products,
        total_credit_products,
        total_associated_cost,
        total_equipment_cost,
        total_driver_cost,
        total_warehouse_cost,
        total_admin_cost,
    ]

    let elems_affecting_product_credits = [
        total_cost_products,
        total_credit_products,
    ]
    let elems_affecting_expenses_cost = [
        total_associated_cost,
        total_equipment_cost,
        total_driver_cost,
        total_warehouse_cost,
        total_admin_cost,
    ]

    function calculate_expenses(event) {
        return elems_affecting_expenses_cost.reduce((total_cost, currentElem) => {
            let current_elem_cost = valueOrZero(parseFloat(currentElem.val()))
            return total_cost + current_elem_cost
        }, 0)
    }

    function calculate_product_credit(event) {
        return elems_affecting_product_credits.reduce((total_cost, currentElem) => {
            let current_elem_cost = valueOrZero(parseFloat(currentElem.val()))
            return total_cost + current_elem_cost
        }, 0)
    }

    function calculate_total_costs(event) {
        let expense_costs = calculate_expenses(event)
        let product_credits = calculate_product_credit(event)
        let total_cost = expense_costs + product_credits
        total_expense_cost.val(expense_costs.toFixed(2))
        total_product_credit.val(product_credits.toFixed(2))
        total_recovery_cost.val(total_cost.toFixed(2))
        total_title_cost.text(`Total Cost: $ ${total_cost.toFixed(2)}`)
        return {
            "expense_cost": expense_costs,
            "product_credit": product_credits,
            "total_cost": total_cost
        }
    }

    // Initialize the total values on load
    calculate_total_costs(null)

    elems_affecting_total.forEach((elem) => {
        // We don't need to attach the individual event handlers since they are called in handle_total_driver_cost
        elem.on("change", calculate_total_costs)
    })
}